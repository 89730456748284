'use client';

import { useId, useState } from 'react';

import classNames from 'classnames';
import { FocusOn } from 'react-focus-on';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { PlacesType, Tooltip } from 'react-tooltip';
import Image from 'next/image';

import useMediaQuery from '@/hooks/useMediaQuery';
import tailwindConfig from '@/tailwind.config';
import { Caret, IconComponent } from '@/components/icons/icons';
import { GhostButton } from '@/components/Fields/Buttons';

export type DropdownItem = {
  icon?: IconComponent;
  image?: string;
  label: string;
  subLabel?: string;
  value: string;
};

type DropdownProps = {
  className?: string;
  children: React.ReactNode;
  items: DropdownItem[];
  selectedItem?: DropdownItem;
  disabled?: boolean;
  place?: PlacesType;
  mobileBlocking?: boolean;
  onSelect?: (item: DropdownItem) => void;
};

export const Dropdown = (props: DropdownProps) => {
  const { className, children, items, disabled, place, onSelect, selectedItem, mobileBlocking } = props;
  const [isOpen, setIsOpen] = useState(false);
  const randomId = useId();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const smallMediaQuery = useMediaQuery((tailwindConfig.theme?.extend?.screens as any)?.['lt-sm']?.raw);

  return (
    <>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className={classNames('fixed inset-0 z-[101] h-full w-full', { 'lt-sm:backdrop-blur-xl': mobileBlocking })}
        ></div>
      )}
      <div className={classNames('flex flex-col gap-4', className)}>
        <button
          className={classNames(
            'flex flex-shrink-0 select-none items-center justify-center gap-1 rounded-lg px-3 py-2 text-sm font-semibold leading-6',
            {
              'hover:bg-primary-500/5 dark:hover:bg-white/5': !disabled,
              'cursor-default': disabled,
            },
          )}
          onClick={() => setIsOpen((prev) => !prev)}
          data-tooltip-id={`dropdown-tooltip-${randomId}`}
          data-dropdown-toggle={`dropdown-${randomId}`}
        >
          {children}
          {!disabled && <Caret className="ml-1.5 h-2.5 w-2.5 opacity-50 transition-opacity group-hover:opacity-100" />}
        </button>
        <Tooltip
          id={`dropdown-tooltip-${randomId}`}
          className={classNames('!rounded-lg !p-0 !shadow-xl ', '!bg-white dark:!bg-gray-800', {
            'lt-sm:!fixed lt-sm:!left-0 lt-sm:!w-full': mobileBlocking,
            'lt-sm:!top-0 lt-sm:!bg-transparent lt-sm:!pt-[5rem] lt-sm:dark:lt-sm:!bg-transparent': mobileBlocking,
            'dark:lt-sm:!bg-transparent': mobileBlocking, //blocking,
            'z-[60]': !isOpen,
            'z-[102]': isOpen,
          })}
          openOnClick
          clickable
          globalCloseEvents={{ escape: true, clickOutsideAnchor: true }}
          opacity={1}
          imperativeModeOnly={disabled}
          isOpen={isOpen}
          place={place ?? 'bottom'}
        >
          <FocusOn enabled={smallMediaQuery}>
            <div
              id={`dropdown-${randomId}`}
              className={classNames('flex flex-col gap-5 overflow-auto', {
                'lt-sm:!h-[calc(100dvh-6rem)] lt-sm:h-[calc(100vh-4rem)]': mobileBlocking,
              })}
            >
              {mobileBlocking && (
                <GhostButton
                  className="fixed top-2 mx-4 flex min-h-[4rem] w-[calc(100%-2rem)] items-center justify-center sm:hidden"
                  onClick={() => setIsOpen(false)}
                >
                  <ArrowUturnLeftIcon className="h-4 w-4" />
                  Close
                </GhostButton>
              )}
              <ul
                className={classNames(
                  '!gap-0',
                  'text-sm text-gray-700 dark:text-gray-200',
                  'sm:py-2 lt-sm:px-3',
                  'select-none',
                )}
                role="listbox"
              >
                {items.map((item) => (
                  <li key={item.value} className="!m-0 !list-none">
                    <a
                      type="button"
                      className={classNames(
                        '!no-underline',
                        'inline-flex w-full cursor-pointer px-2 py-3 text-sm sm:px-4',
                        'text-gray-700 hover:text-black dark:text-gray-400 dark:hover:text-white',
                        {
                          'font-bold !text-black dark:!text-white': item.value === selectedItem?.value,
                          // 'bg-gray-100 dark:bg-gray-600': useLanguageContext && languages.includes(locale.id),
                        },
                      )}
                      // href={redirectOnClick ? getLocaleSpecificUrl(locale.id, pathname) : undefined}
                      // onClick={() => selectLocaleHandler(locale.id)}
                      // lang={locale.id}
                      aria-selected={item.value === selectedItem?.value}
                      onClickCapture={() => {
                        onSelect?.(item);
                        setIsOpen(false);
                      }}
                      role="option"
                    >
                      <div className="inline-flex items-center">
                        {item?.icon && <item.icon className="mr-2 h-3.5 w-3.5" aria-hidden="true" />}
                        {item?.image && (
                          <Image
                            className="mr-2 rounded-full"
                            width={32}
                            height={32}
                            aria-hidden="true"
                            src={item.image}
                            alt={item.label}
                          />
                        )}

                        <div className="flex flex-col">
                          {item.label}
                          <div className="inline-flex items-center opacity-50 empty:hidden">{item.subLabel}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </FocusOn>
        </Tooltip>
      </div>
    </>
  );
};
