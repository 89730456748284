'use client';

import { useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useTheme } from 'next-themes';

import { colors } from '@/constants/tailwindColors';
import { useIsIntersecting } from '@/hooks/useIsIntersecting';

export const Particles = ({ className }: { className?: string }) => {
  const { theme } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const setFPSCallback = useRef<(fps: number) => void>();
  const isIntersecting = useIsIntersecting(containerRef);

  const darkBg = colors.background['950'];
  const lightBg = colors.background['200'];

  useEffect(() => {
    const { init, setCanvasBg, setFPS } = require('./particlesRenderer');
    setFPSCallback.current = setFPS;
    init();
    setCanvasBg(theme === 'dark' ? darkBg : lightBg);
  }, []);

  useEffect(() => {
    const { setCanvasBg, setFPS } = require('./particlesRenderer');
    setFPSCallback.current = setFPS;
    setCanvasBg(theme === 'dark' ? darkBg : lightBg);
  }, [theme]);

  useEffect(() => {
    if (!setFPSCallback.current) return;
    setFPSCallback.current(isIntersecting ? 120 : 0);
  }, [isIntersecting]);

  return (
    <div
      ref={containerRef}
      id="canvas-container"
      className={classNames(
        'absolute left-0 top-0 h-full w-full bg-background-200/50 dark:bg-background-950 print:!hidden',
        {
          '!hidden': process.env.IS_DEV === '1',
        },
        className,
      )}
      suppressHydrationWarning
    >
      <canvas className="p-canvas-webgl absolute inset-0 opacity-20" id="canvas-webgl"></canvas>

      <script
        id="vs-physics-renderer"
        type="x-shader/x-vertex"
        dangerouslySetInnerHTML={{
          __html: `
        varying vec2 vUv;
  
        void main(void) {
          vUv = uv;
          gl_Position = vec4(position, 1.0);
        }
      `,
        }}
      />

      <script
        id="fs-physics-renderer-velocity"
        type="x-shader/x-fragment"
        dangerouslySetInnerHTML={{
          __html: `
         uniform sampler2D velocity;
         uniform sampler2D acceleration;
         uniform float time;
         
         varying vec2 vUv;
         
         vec3 polar(float radian1, float radian2, float radius) {
           return vec3(
             cos(radian1) * cos(radian2) * radius,
             sin(radian1) * radius,
             cos(radian1) * sin(radian2) * radius
           );
         }
         
         void main(void) {
           vec3 v = texture2D(acceleration, vUv).xyz + texture2D(velocity, vUv).xyz;
           float vStep = step(1000.0, length(v));
           gl_FragColor = vec4(
             v * (1.0 - vStep) + normalize(v + polar(time, -time, 1.0)) * 80.0 * vStep,
             1.0
           );
         }
      `,
        }}
      />

      <script
        id="fs-physics-renderer-acceleration"
        type="x-shader/x-fragment"
        dangerouslySetInnerHTML={{
          __html: `
        uniform vec2 resolution;
        uniform sampler2D velocity;
        uniform sampler2D acceleration;
        uniform float time;
        uniform vec2 vTouchMove;
        
        varying vec2 vUv;
        
        vec3 mod289(vec3 x)
        {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        
        vec4 mod289(vec4 x)
        {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        
        vec4 permute(vec4 x)
        {
          return mod289(((x*34.0)+1.0)*x);
        }
        
        vec4 taylorInvSqrt(vec4 r)
        {
          return 1.79284291400159 - 0.85373472095314 * r;
        }
        
        vec3 fade(vec3 t) {
          return t*t*t*(t*(t*6.0-15.0)+10.0);
        }
        
        float cnoise(vec3 P)
        {
          vec3 Pi0 = floor(P); // Integer part for indexing
          vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
          Pi0 = mod289(Pi0);
          Pi1 = mod289(Pi1);
          vec3 Pf0 = fract(P); // Fractional part for interpolation
          vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
          vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
          vec4 iy = vec4(Pi0.yy, Pi1.yy);
          vec4 iz0 = Pi0.zzzz;
          vec4 iz1 = Pi1.zzzz;
        
          vec4 ixy = permute(permute(ix) + iy);
          vec4 ixy0 = permute(ixy + iz0);
          vec4 ixy1 = permute(ixy + iz1);
        
          vec4 gx0 = ixy0 * (1.0 / 7.0);
          vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
          gx0 = fract(gx0);
          vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
          vec4 sz0 = step(gz0, vec4(0.0));
          gx0 -= sz0 * (step(0.0, gx0) - 0.5);
          gy0 -= sz0 * (step(0.0, gy0) - 0.5);
        
          vec4 gx1 = ixy1 * (1.0 / 7.0);
          vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
          gx1 = fract(gx1);
          vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
          vec4 sz1 = step(gz1, vec4(0.0));
          gx1 -= sz1 * (step(0.0, gx1) - 0.5);
          gy1 -= sz1 * (step(0.0, gy1) - 0.5);
        
          vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
          vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
          vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
          vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
          vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
          vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
          vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
          vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
        
          vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
          g000 *= norm0.x;
          g010 *= norm0.y;
          g100 *= norm0.z;
          g110 *= norm0.w;
          vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
          g001 *= norm1.x;
          g011 *= norm1.y;
          g101 *= norm1.z;
          g111 *= norm1.w;
        
          float n000 = dot(g000, Pf0);
          float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
          float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
          float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
          float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
          float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
          float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
          float n111 = dot(g111, Pf1);
        
          vec3 fade_xyz = fade(Pf0);
          vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
          vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
          float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
          return 2.2 * n_xyz;
        }
        
        #define PRECISION 0.000001
        vec3 drag(vec3 a, float value) {
          return normalize(a * -1.0 + PRECISION) * length(a) * value;
        }
        
        void main(void) {
          vec3 v = texture2D(velocity, vUv).xyz;
          vec3 a = texture2D(acceleration, vUv).xyz;
          vec3 d = drag(a, 0.02);
          float fx = cnoise(vec3(time * 0.1, v.y / 500.0, v.z / 500.0));
          float fy = cnoise(vec3(v.x / 500.0, time * 0.1, v.z / 500.0));
          float fz = cnoise(vec3(v.x / 500.0, v.y / 500.0, time * 0.1));
          vec3 f1 = vec3(fx, fy, fz) * 0.12;
          vec3 f2 = vec3(vTouchMove * 10.0, 0.0);
          gl_FragColor = vec4(a + f1 + f2 + d, 1.0);
        }
      `,
        }}
      />

      <script
        id="vs-points"
        type="x-shader/x-vertex"
        dangerouslySetInnerHTML={{
          __html: `
          attribute vec3 position;
          attribute vec2 uvVelocity;
          
          uniform mat4 modelViewMatrix;
          uniform mat4 projectionMatrix;
          uniform float time;
          uniform sampler2D acceleration;
          uniform sampler2D velocity;
          
          varying vec3 vAcceleration;
          
          void main() {
            vec3 a = texture2D(acceleration, uvVelocity).xyz;
            vec3 v = texture2D(velocity, uvVelocity).xyz;
            vec4 mvPosition = modelViewMatrix * vec4(v, 1.0);
            vAcceleration = a;
            gl_PointSize = 1.0 * (1200.0 / length(mvPosition.xyz));
            gl_Position = projectionMatrix * mvPosition;
          }
          
      `,
        }}
      />

      <script
        id="fs-points"
        type="x-shader/x-fragment"
        dangerouslySetInnerHTML={{
          __html: `
         precision highp float;
  
         uniform float time;
         
         varying vec3 vAcceleration;
         
         vec3 convertHsvToRgb(vec3 c) {
           vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
           vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
           return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
         }
         
         void main() {
           float start = smoothstep(time, 0.0, 1.0);
           vec3 n;
           n.xy = gl_PointCoord * 2.0 - 1.0;
           n.z = 1.0 - dot(n.xy, n.xy);
           if (n.z < 0.0) discard;
           float aLength = length(vAcceleration);
           vec3 color = convertHsvToRgb(vec3(aLength * 0.08 + time * 0.05, 0.5, 0.8));
           gl_FragColor = vec4(color, 0.4 * start);
         }
        `,
        }}
      />
    </div>
  );
};
