import { useEffect, useId, useState } from 'react';

import classNames from 'classnames';

import { debounce } from '@/utils/debounce';

type TabProps = {
  className?: string;
  children: React.ReactNode;
  activeTab: string | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  id: string;
};

export const Tab = ({ className, children, id, activeTab, setActiveTab }: TabProps) => (
  <div
    suppressHydrationWarning
    className={classNames(
      'px-2 py-2 sm:py-3 md:px-3 lg:px-5',
      'text-sm lg:text-lg lt-tiny:text-[11px]',
      'font-semibold',
      'justify-center text-center',
      'cursor-pointer select-none',
      'lt-tiny:flex-0 flex-1',
      'transition-all',
      'border-b-2 border-transparent',
      'bg-primary-200/20 dark:bg-primary-700/5',
      'border-primary-100 dark:border-primary-800/50',
      'hover:border-primary-200 dark:hover:border-primary-700/50',
      {
        '!border-primary-400 dark:!border-primary-500': activeTab === id,
      },
      className,
    )}
    onClick={() => {
      setActiveTab(id);
      // window.history.pushState({}, '', `?tab=${id}`);
    }}
  >
    {children}
  </div>
);

export const TabContent = ({ children, active }: { children: React.ReactNode; active: boolean }) => (
  <div className={classNames('tab-content', { hidden: !active, active: active })}>{children}</div>
);

export const TabContentContainer = ({
  activeTab,
  children,
  className,
}: {
  activeTab?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const [maxHeight, setMaxHeight] = useState<number | undefined>();
  const id = useId().replaceAll(':', '');

  useEffect(() => {
    const updateHeight = () => {
      const tabHeight = document.querySelector(`#${id} .tab-content.active`)?.clientHeight;
      setMaxHeight(tabHeight);
    };

    const onResize = debounce(() => setMaxHeight(undefined), 100);
    setTimeout(updateHeight);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [activeTab, id]);

  return (
    <div
      id={id}
      className={classNames('overflow-clip transition-all empty:hidden', className)}
      style={{ maxHeight: maxHeight, height: maxHeight }}
    >
      {children}
    </div>
  );
};
