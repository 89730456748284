/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { Timeline } from '@vocalstack/js-sdk';
import classNames from 'classnames';

import { TranscriptionLanguages } from './TranscriptionLanguages';

import { useTranslations } from '@/context/TranslationsContext';
import { AlternativeLanguageSelector } from '@/components/Navigation/SecondaryNavigation/LanguageSelector';
import { LocaleKey } from '@/constants/locales';
import { formatSeconds } from '@/utils/formatSeconds';
import { AutoSnap } from '@/components/widgets/AutoSnap/AutoSnap';

type TimelineWithPendingAndLatest = (Timeline[0] & {
  pending?: boolean;
  latest?: boolean;
})[];

type ChunksTimelineProps = {
  timeline: Timeline;
  variant?: 'preview';
  languagesLoading: LocaleKey[];
  autoScroll?: boolean;
  showLanguageSelector?: boolean;
  showLanguagesBar?: boolean;
  capTimeAt?: number;
  lastVisibleItemIndex?: number;
  hideDetected?: boolean;
  className?: string;
};

const TRANSLATIONS_LIMIT = 5;

export const ChunksTimeline = (props: ChunksTimelineProps) => {
  const { variant, languagesLoading, autoScroll, showLanguagesBar, showLanguageSelector, className } = props;
  const { hideDetected, capTimeAt, lastVisibleItemIndex } = props;
  const { translations, translationsWithoutDetected } = useTranslations();

  const timeline = props.timeline as unknown as TimelineWithPendingAndLatest;

  const _languages = variant === 'preview' ? ['Detected'] : hideDetected ? translationsWithoutDetected : translations;
  const _timeline = variant === 'preview' ? timeline.slice(-4) : timeline;

  if (!_timeline?.length) {
    return null;
  }

  return (
    <div className={classNames('flex w-full flex-col gap-2 overflow-hidden sm:gap-5', className)}>
      {showLanguageSelector && <AlternativeLanguageSelector className="ml-[45px]" />}
      {showLanguagesBar && (
        <TranscriptionLanguages
          timeline={timeline}
          languagesLoading={languagesLoading}
          readonly={!showLanguageSelector}
          hideDetected={hideDetected}
          limit={TRANSLATIONS_LIMIT}
        />
      )}
      <AutoSnap
        className={classNames('flex flex-col gap-2', {
          '!max-h-[calc(100dvh-8rem)] max-h-[calc(100vh-8rem)]': !showLanguageSelector,
          '!max-h-[calc(100dvh-11rem)] max-h-[calc(100vh-11rem)]': showLanguageSelector,
        })}
        disabled={!autoScroll}
      >
        {_timeline
          ?.filter(({ start }) => typeof capTimeAt === 'undefined' || Number(start) < capTimeAt)
          .filter((_, index) => index <= (lastVisibleItemIndex ?? Infinity))
          .map(({ text, start, translations, latest, pending }, index) => (
            <div
              key={index}
              className={classNames('flex items-center gap-2 transition-all duration-1000', {
                'animate-slide-up': index === _timeline.length - 1,
              })}
            >
              <div
                className={classNames(
                  'w-[45px] whitespace-nowrap text-right text-xs font-light tabular-nums transition-all',
                  { 'opacity-40': !latest, 'opacity-80': latest },
                )}
              >
                {formatSeconds(Number(start))}
              </div>
              <div className="flex w-full">
                <div className="flex w-full gap-2 lt-sm:gap-1">
                  {_languages.slice(0, TRANSLATIONS_LIMIT).map((lang) => (
                    <div
                      key={lang}
                      style={{ overflowWrap: 'anywhere' }}
                      className={classNames('flex flex-1 rounded-md', 'px-4 py-2 lt-sm:px-2 lt-sm:py-1.5', {
                        'even-chunks-row-bg bg-secondary-100 dark:bg-secondary-800/60': index % 2,
                        'flex-grow items-center gap-2 opacity-50': pending,
                      })}
                    >
                      {lang === 'Detected'
                        ? text
                        : (translations as any)?.[lang]?.replaceAll('&apos;', "'").replaceAll(' &apos;', "'")}
                      {pending && '...'}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </AutoSnap>
    </div>
  );
};
