'use client';

import { createContext, useContext } from 'react';

import { LocaleKey } from '@/constants/locales';

export type GlobalContext = {
  apiServer: string;
  host: string;
  protocol: string;
  localeKey: LocaleKey;
  hostNoWWW: string;
};

const GlobalContext = createContext<GlobalContext>({
  apiServer: '',
  host: '',
  protocol: '',
  localeKey: 'en',
  hostNoWWW: '',
});

type GlobalProviderProps = {
  children: React.ReactNode;
  apiServer: string;
  host: string;
  protocol: string;
  localeKey: LocaleKey;
};

export const GlobalProvider = (props: GlobalProviderProps) => {
  const { children, apiServer, host, protocol, localeKey } = props;

  const value = {
    apiServer,
    host,
    hostNoWWW: host.replace('www.', ''),
    protocol,
    localeKey,
  };
  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export function useGlobal() {
  return useContext(GlobalContext);
}
