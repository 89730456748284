'use client';

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import {
  CheckCircleIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  LinkIcon,
  MicrophoneIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import Image from 'next/image';
import { PlayCircleIcon } from '@heroicons/react/24/solid';

import { PulseAnimation } from '../polyglot/PolyglotExample/PulseAnimation/PulseAnimation';

import { useTranslation } from '@/hooks/useTranslation';
import { GhostAnchor, primaryColorsCSS } from '@/components/Fields/Buttons';
import { Heading } from '@/components/Fields/Heading';
import { Mp3Icon, PolyglotIcon, VocalStackIcon } from '@/components/icons/icons';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { AutoSnap } from '@/components/widgets/AutoSnap/AutoSnap';
import { TabContent, TabContentContainer } from '@/components/widgets/Tabs';
import { PricingButton } from '@/components/Pricing/PricingButton';
import { prices } from '@/constants/pricingData';
import { Translation } from '@/components/Translation';
import { Toggle } from '@/components/Fields/Toggle';

type FeatureType = 'file' | 'link' | 'microphone' | 'livestream';
type LiveType = 'Standard' | 'Polyglot';

const buildTranscriptionUrl = (feature: FeatureType, liveType: LiveType) => {
  if (typeof window === 'undefined') return '';
  const url = new URL(window.location.origin + window.location.pathname);
  url.searchParams.set('transcription-type', feature);
  url.searchParams.set('live-type', liveType);
  return url.toString();
};

export const HomePageWhatIsVocalStack = ({ feature }: { feature?: FeatureType }) => {
  const description = useTranslation(
    ['what-is-vs', 'description'],
    'VocalStack is the best solution to transcribe audio to text with instant translation and live sharing capabilities! It is an audio to text converter app and a real time translator app powered by large AI models like OpenAI’s Whisper',
  );

  // const keywords = [
  //   'transcribe audio to text', // KD: 18, SV: 14k - commercial, transactional
  //   'audio to text converter', // KD: 34, SV: 6.8k - commercial, transactional
  //   'real time translator app', // KD: 69, SV: 400 - commercial, transactional
  //   'audio to text transcription', // KD: 39, SV: 1.7k - informational
  //   'convert mp3 to text',
  //   'real time translation', // KD: 27, SV: 450
  //   'ai live translation', // KD: 21, SV: 150
  //   'ai voice transcription', // KD: 23, SV: 150 - commercial, informative
  //   'live audio translation', // KD: 37, SV: 200
  //   'instant translation', // KD: 37, SV: 200
  // ];

  const features: Record<FeatureType, string[]> = {
    file: [
      useTranslation(
        ['what-is-vs', 'file', 'transcribe-audio'],
        'Transcribe audio to text directly from files on your device',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'high-speed-transcription'],
        'High-speed transcription: Convert one hour of audio in about one minute',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'incremental-loading'],
        'Incremental loading: Receive partial results while transcription is in progress',
      ),
      useTranslation(
        ['what-is-vs', 'file', 'supported-formats'],
        'Supported formats include: MP3, M4A, WAV, AAC, FLAC, OGG, WMA, AIFF',
      ),
    ],
    link: [
      useTranslation(['what-is-vs', 'link', 'transcribe-via-url'], 'Transcribe audio files to text via a URL'),
      useTranslation(
        ['what-is-vs', 'link', 'no-download'],
        'No need to download audio files to your device before transcribing',
      ),
      useTranslation(
        ['what-is-vs', 'link', 'auto-detect-formats'],
        'Automatically detect and process various audio formats from the provided URL',
      ),
      useTranslation(
        ['what-is-vs', 'link', 'same-benefits'],
        'Enjoy the same benefits and functionality as file transcriptions',
      ),
    ],
    microphone: [
      useTranslation(
        ['what-is-vs', 'microphone', 'real-time-transcription'],
        'Real-time audio to text transcription from your microphone',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'vocalstack-dashboard'],
        'Use your VocalStack Dashboard to record from any device with a web browser',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'low-latency'],
        'Low latency processing for instant transcription results',
      ),
      useTranslation(
        ['what-is-vs', 'microphone', 'translate-live'],
        'Translate live transcriptions into over 50 languages in real-time',
      ),
    ],
    livestream: [
      useTranslation(
        ['what-is-vs', 'livestream', 'transcribe-live-streams'],
        'Transcribe audio to text from live streams',
      ),
      useTranslation(['what-is-vs', 'livestream', 'supports-hls'], 'Supports HLS (HTTP Live Streaming) playlist files'),
      useTranslation(
        ['what-is-vs', 'livestream', 'same-benefits-microphone'],
        'Experience the same benefits and functionality as microphone transcriptions',
      ),
    ],
  };

  const polyglotFeatures = [
    useTranslation(
      ['what-is-vs', 'polyglot', 'unique-transcription-link'],
      'Create a unique transcription link to share with others',
    ),
    useTranslation(
      ['what-is-vs', 'polyglot', 'read-live'],
      'Your users can read the live transcriptions in their preferred language',
    ),
    useTranslation(
      ['what-is-vs', 'polyglot', 'multiple-languages'],
      'Supports multiple languages in a single transcription',
    ),
    useTranslation(['what-is-vs', 'polyglot', 'no-download'], 'No need to download or install any software'),
  ];

  const [activeFeature, setActiveFeature] = useState<FeatureType>(feature ?? 'file');
  const [liveType, setLiveType] = useState<LiveType>('Standard');
  const mounted = useRef(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const polyglot = liveType === 'Polyglot' && (activeFeature === 'microphone' || activeFeature === 'livestream');

  useEffect(() => {
    if (mounted.current) return;
    setTimeout(() => (mounted.current = true));
    const url = new URL(window.location.href);
    const urlFeature = url.searchParams.get('transcription-type');
    const urlLiveType = url.searchParams.get('live-type');
    if (urlFeature && Object.keys(features).includes(urlFeature)) {
      setActiveFeature(urlFeature as FeatureType);
    }
    if (urlLiveType && ['Standard', 'Polyglot'].includes(urlLiveType)) {
      setLiveType(urlLiveType as LiveType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted.current) return;
    const url = new URL(window.location.href);
    url.searchParams.set('transcription-type', activeFeature);
    window.history.replaceState({}, '', url.toString());
  }, [activeFeature]);

  useEffect(() => {
    if (!mounted.current) return;
    const url = new URL(window.location.href);
    url.searchParams.set('live-type', liveType);
    window.history.replaceState({}, '', url.toString());
  }, [liveType]);

  useEffect(() => {
    if (!mounted.current) return;
    setActiveFeature((feature) => {
      if (feature === 'file' || feature === 'link') {
        return 'microphone';
      }
      return feature;
    });
  }, [liveType]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            if (entry.boundingClientRect.top > 0) return;
            const url = new URL(window.location.href);
            url.searchParams.delete('transcription-type');
            url.searchParams.delete('live-type');
            url.hash = '';
            window.history.replaceState({}, '', url.toString());
          } else if (mounted.current) {
            const newUrl = buildTranscriptionUrl(activeFeature, liveType);
            const finalUrl = `${newUrl}#what-is-vocalstack`;
            window.history.replaceState({}, '', finalUrl);
          }
        });
      },
      { threshold: 0.5 },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [activeFeature, liveType]);

  return (
    <>
      <div className="bg-white pb-32 pt-20 dark:bg-background-800 sm:pt-28">
        <MaxWidthContainer className="flex flex-col gap-10 sm:gap-20">
          <Heading id="what-is-vocalstack" level={2} className="mx-auto justify-center">
            <Translation name="what-is-vs.title">What is VocalStack?</Translation>
          </Heading>

          <p className="text-balance text-center text-lg">{description}</p>

          {/* <div className="flex flex-wrap items-center justify-center gap-3 py-1">
            {keywords.map((keyword) => (
              <span key={keyword} className="rounded-lg bg-primary-400/10 px-4 dark:bg-primary-600/20">
                <span className="opacity-60">{keyword}</span>
              </span>
            ))}
          </div> */}

          <TabContentContainer activeTab={`${activeFeature}-${polyglot ? 1 : 0}`} className="!overflow-visible">
            <TabContent active>
              <div
                ref={componentRef}
                className="flex items-center gap-10 lg:gap-20 lt-md:mx-auto lt-md:max-w-md lt-md:flex-col"
              >
                <Headings
                  activeFeature={activeFeature}
                  setActiveFeature={setActiveFeature}
                  liveType={liveType}
                  setLiveType={setLiveType}
                />
                <div className="flex w-full flex-col">
                  <Animation feature={activeFeature} liveType={liveType} />

                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'file' })}
                    features={features.file}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'link' })}
                    features={features.link}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'microphone' })}
                    features={features.file}
                    fixBg
                  />
                  <Highlights
                    className={classNames({ hidden: activeFeature !== 'livestream' })}
                    features={features.file}
                    fixBg
                  />

                  <div
                    className={classNames(
                      'flex items-center justify-center transition-all before:border-t-[1px] before:transition-all before:duration-300 after:border-t-[1px] after:transition-all after:duration-300',
                      'before:border-emerald-400 after:border-emerald-400',
                      {
                        'my-10 before:w-full after:w-full': polyglot,
                        'before:w-0 after:w-0': !polyglot,
                      },
                    )}
                  >
                    <span
                      className={classNames('relative px-4 text-xl font-black transition-all duration-300', {
                        'scale-0': !polyglot,
                      })}
                    >
                      <PolyglotIcon className="w-12 text-emerald-400" />
                    </span>
                  </div>
                  {polyglot && (
                    <div className="-m-5 rounded-lg bg-emerald-400/10 p-5 dark:bg-emerald-600/20">
                      <Highlights features={polyglotFeatures} />
                    </div>
                  )}
                  <Buttons feature={activeFeature} />
                </div>
              </div>
            </TabContent>
          </TabContentContainer>
        </MaxWidthContainer>
      </div>
      <Video />
    </>
  );
};

const Headings = ({
  activeFeature,
  setActiveFeature,
  liveType,
  setLiveType,
}: {
  activeFeature: FeatureType;
  setActiveFeature: Dispatch<SetStateAction<FeatureType>>;
  liveType: LiveType;
  setLiveType: Dispatch<SetStateAction<LiveType>>;
}) => {
  const HeadingTab = ({
    children,
    active,
    feature,
  }: {
    children: React.ReactNode;
    active?: boolean;
    feature: FeatureType;
  }) => (
    <a
      href={buildTranscriptionUrl(feature, liveType)}
      onClick={(e) => {
        e.preventDefault();
        setActiveFeature(feature);
      }}
      suppressHydrationWarning
    >
      <Heading
        level={4}
        className={classNames(
          'cursor-pointer justify-center py-4 !text-base font-light transition-all md:p-4 lt-sm:!text-sm',
          {
            'bg-emerald-300 dark:bg-emerald-600': active,
            'hover:bg-emerald-300 hover:dark:bg-emerald-600': !active,
            'bg-emerald-400/20 dark:bg-emerald-600/20': !active,
          },
        )}
        onClick={() => setActiveFeature(feature)}
      >
        {children}
      </Heading>
    </a>
  );
  const sectionCSS = 'flex flex-col rounded-lg bg-primary-400/10 p-3 md:p-5 dark:bg-primary-600/20 lt-md:w-1/2 gap-0.5';
  const isLive = activeFeature === 'microphone' || activeFeature === 'livestream';
  const toggle = (
    <span onClick={() => !isLive && setActiveFeature('microphone')}>
      <Toggle
        className={classNames('mx-auto my-4 whitespace-nowrap md:mt-1', {
          '[&_.toggle-slider]:dark:!bg-gray-900/50': !isLive,
          '[&_.toggle-slider]:!bg-emerald-300 [&_.toggle-slider]:dark:!bg-emerald-600': isLive,
        })}
        value1="Standard"
        value2="Polyglot"
        icons={[VocalStackIcon, PolyglotIcon]}
        activeValue={liveType}
        onChange={(val) => setLiveType(val as LiveType)}
        disabled={!isLive}
      />
    </span>
  );
  return (
    <>
      <div className="flex select-none gap-2 md:mb-auto md:flex-col md:gap-4">
        <div className={sectionCSS}>
          <h3 className="mb-4 justify-center text-center text-xs font-bold lt-md:mb-auto">
            <Translation name="what-is-vs.pre-recorded-title">Pre-recorded Speech Transcription</Translation>
          </h3>
          <HeadingTab feature="file" active={activeFeature === 'file'}>
            <Translation name="what-is-vs.file-to-text" nonDefaultLocaleText="Transcribe Audio Files to Text">
              Audio Files to Text
            </Translation>
          </HeadingTab>
          <HeadingTab feature="link" active={activeFeature === 'link'}>
            <Translation name="what-is-vs.link-to-text" nonDefaultLocaleText="Transcribe Audio Links to Text">
              Audio Link to Text
            </Translation>
          </HeadingTab>
        </div>
        <div className={sectionCSS}>
          <h3 className="mb-4 justify-center text-center text-xs font-bold lt-md:mb-auto">
            <Translation name="what-is-vs.live-title">Live Speech Transcription</Translation>
          </h3>
          <span className="lt-md:hidden">{toggle}</span>
          <HeadingTab feature="microphone" active={activeFeature === 'microphone'}>
            <Translation name="what-is-vs.mic-to-text" nonDefaultLocaleText="Transcribe from Microphone to Text">
              Microphone to Text
            </Translation>
          </HeadingTab>
          <HeadingTab feature="livestream" active={activeFeature === 'livestream'}>
            <Translation name="what-is-vs.livestream-to-text" nonDefaultLocaleText="Transcribe from LiveStream to Text">
              LiveStream to Text
            </Translation>
          </HeadingTab>
        </div>
      </div>
      <span className="-mt-5 md:hidden">{toggle}</span>
    </>
  );
};

const Animation = ({ feature, liveType }: { feature: FeatureType; liveType: LiveType }) => {
  const iconCSS =
    'w-10 shrink-0 animate-ping [animation-direction:reverse] [animation-duration:200ms] dark:text-emerald-500 text-emerald-400 md:w-20 [animation-iteration-count:1]';
  const polyglot = liveType === 'Polyglot' && (feature === 'microphone' || feature === 'livestream');
  const Icon = polyglot ? PolyglotIcon : VocalStackIcon;
  return (
    <div className="flex w-full items-center gap-1 lg:gap-4 lt-md:-translate-y-5">
      <div>
        <Mp3Icon className={iconCSS} style={{ display: feature === 'file' ? '' : 'none' }} />
        <LinkIcon className={iconCSS} style={{ display: feature === 'link' ? '' : 'none' }} />
        <MicrophoneIcon className={iconCSS} style={{ display: feature === 'microphone' ? '' : 'none' }} />
        <VideoCameraIcon className={iconCSS} style={{ display: feature === 'livestream' ? '' : 'none' }} />
      </div>
      <PulseAnimation className="w-full opacity-50" />
      <span className="relative">
        <Icon className="absolute inset-0 z-20 m-auto w-4 text-emerald-400 translate-y-[1px] dark:text-emerald-500 md:w-8" />
        <span className="absolute inset-0 z-10 m-auto h-6 w-6 rounded-full bg-white dark:bg-background-800 md:h-12 md:w-12" />
        <Cog6ToothIcon className="w-14 animate-spin text-emerald-400 [animation-duration:6s] dark:text-emerald-500 md:w-28" />
      </span>
      <PulseAnimation className="w-full opacity-50 -translate-x-2" />
      <div className="relative flex aspect-square w-48 shrink-0 items-center justify-center md:w-52 lg:w-60 xl:w-80">
        {!polyglot && <TextAnimation />}
        {polyglot && <PolyglotAnimation />}
      </div>
    </div>
  );
};

const PolyglotAnimation = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => setMounted(true));
    return () => setMounted(false);
  }, [mounted]);

  const inside = (
    <>
      <DevicePhoneMobileIcon className="absolute w-full stroke-current stroke-[0.6px] text-emerald-400 dark:text-emerald-500" />
      <TextAnimation className="!max-h-full scale-[0.4]" />
    </>
  );
  return (
    <div
      className={classNames('translate-all h-full w-full', {
        '-translate-x-1/3': mounted,
      })}
      style={{ transformStyle: 'preserve-3d', perspective: '1000px' }}
    >
      <div
        className={classNames('h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          'rotate-y-[-30deg]': mounted,
        })}
      >
        {inside}
      </div>
      <div
        className={classNames('absolute top-0 h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          '-translate-y-1/2 translate-x-1/2 rotate-y-[-30deg] scale-75 lt-md:-translate-y-1/3 lt-md:scale-50': mounted,
        })}
      >
        {inside}
      </div>
      <div
        className={classNames('absolute top-0 h-full w-full transition-all', {
          'rotate-y-0': !mounted,
          'translate-x-1/2 translate-y-1/2 rotate-y-[-30deg] scale-75 lt-md:translate-y-1/3 lt-md:scale-50': mounted,
        })}
      >
        {inside}
      </div>
    </div>
  );
};

const TextAnimation = ({ className }: { className?: string }) => {
  const duration = 5000;
  const [items, setItems] = useState([{ width: 100 }, { width: 75 }, { width: 90 }, { width: 60 }]);
  const itemsRef = useRef(items);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const generateNewWidth = () => {
    const min = 50;
    const max = 100;
    const lastFinalWidth = itemsRef.current[itemsRef.current.length - 1].width;
    const lowerLimit = Math.floor(lastFinalWidth * 0.8);
    const upperLimit = Math.ceil(lastFinalWidth * 1.2);
    const possibleRanges = [];
    if (lowerLimit >= min) {
      possibleRanges.push([min, lowerLimit]);
    }
    if (upperLimit <= max) {
      possibleRanges.push([upperLimit, max]);
    }

    if (possibleRanges.length === 0) {
      return Math.floor(Math.random() * 51) + 50;
    }
    const [rangeMin, rangeMax] = possibleRanges[Math.floor(Math.random() * possibleRanges.length)];
    return Math.floor(Math.random() * (rangeMax - rangeMin + 1)) + rangeMin;
  };

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    const runAnimation = () => {
      if (document.visibilityState !== 'visible') return;
      const width = generateNewWidth();
      setItems((prev) => [...prev, { width: 0.2 * width }]);
      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width: 0.5 * width };
            return newItems;
          });
        }, duration / 4),
      );

      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width: 0.75 * width };
            return newItems;
          });
        }, duration / 2),
      );

      timeouts.push(
        setTimeout(
          () => {
            setItems((prev) => {
              const newItems = [...prev];
              const lastIndex = newItems.length - 1;
              newItems[lastIndex] = { ...newItems[lastIndex], width: 0.9 * width };
              return newItems;
            });
          },
          (duration / 4) * 3,
        ),
      );

      timeouts.push(
        setTimeout(() => {
          setItems((prev) => {
            const newItems = [...prev];
            const lastIndex = newItems.length - 1;
            newItems[lastIndex] = { ...newItems[lastIndex], width };
            return newItems;
          });
        }, duration - 100),
      );
    };

    runAnimation();
    const intervalId = setInterval(runAnimation, duration);

    return () => {
      clearInterval(intervalId);
      timeouts.forEach(clearTimeout);
    };
  }, []); // run once

  return (
    <AutoSnap
      className={classNames(
        'flex h-full max-h-24 w-full flex-col justify-center gap-4 !overflow-hidden md:max-h-32',
        className,
      )}
    >
      {items.map(({ width }, index) => (
        <span
          key={index}
          className={classNames(
            'h-3 shrink-0 rounded-xl bg-emerald-400 transition-all duration-500 dark:bg-emerald-500 md:h-4',
            {
              'animate-slide-up opacity-40': index === items.length - 1,
            },
          )}
          style={{ width: `${width}%` }}
        />
      ))}
    </AutoSnap>
  );
};

const Highlights = ({ className, features, fixBg }: { className?: string; features: string[]; fixBg?: boolean }) => (
  <ul
    className={classNames(
      'text-balance flex flex-col justify-center gap-8 font-light sm:max-w-lg lg:max-w-none lt-lg:mx-auto',
      className,
    )}
  >
    {features.map((feature, index) => (
      <li
        key={feature}
        className={classNames(
          'mr-auto flex animate-slide-down items-center gap-2 rounded-lg pr-3 font-medium tracking-wide',
          {
            '[&_li]:bg-white/80 [&_li]:dark:bg-background-800/80': fixBg,
          },
        )}
        style={{ animationDelay: `${index * 0.1}s` }}
      >
        <CheckCircleIcon className="w-6 shrink-0 text-emerald-400" />
        {feature}
      </li>
    ))}
  </ul>
);

const Buttons = ({ feature }: { feature: FeatureType }) => {
  const readMoreLinks: Partial<Record<FeatureType, string>> = {
    file: '/articles/fundamentals/understanding-online-transcription-tools#how-vocalstack-works',
    link: '/articles/fundamentals/understanding-online-transcription-tools#how-vocalstack-works',
    // microphone: '/articles/tutorial/live-transcriptions-with-translations-shared-via-a-public-url',
    // livestream: '/articles/tutorial/live-transcriptions-with-translations-shared-via-a-public-url',
  };

  const apiLinks: Record<FeatureType, string> = {
    file: '/documentation/transcribe-audio-from-url-to-text',
    link: '/documentation/transcribe-audio-from-url-to-text',
    microphone: '/documentation/transcribe-from-a-microphone-or-live-stream',
    livestream: '/documentation/transcribe-from-a-microphone-or-live-stream',
  };
  return (
    <div className="mt-10 flex gap-10 lg:justify-between lt-lg:flex-col">
      <div className="flex flex-col gap-10">
        <Translation name="try.body" className="text-balance font-light opacity-80">
          Create a free account to get instant access to the VocalStack Dashboard. It only takes a few seconds to get
          started.
        </Translation>
        <PricingButton plan={prices.hobby} location="table" className={classNames('mr-auto', primaryColorsCSS)}>
          <Translation name="button.get-started" nonDefaultLocaleText="Start Now">
            Get Started
          </Translation>
        </PricingButton>
      </div>
      <div className="mt-auto flex gap-6 whitespace-nowrap">
        <GhostAnchor
          href={readMoreLinks[feature]}
          className={classNames('transition-all', { 'scale-0 lt-lg:hidden': !readMoreLinks[feature] })}
        >
          <Translation name="button.learn-more">Learn More</Translation>
        </GhostAnchor>
        <GhostAnchor href={apiLinks[feature]}>
          <Translation name="button.api-documentation">API Documentation</Translation>
        </GhostAnchor>
      </div>
    </div>
  );
};

const Video = () => {
  const [ready, setReady] = useState(false);

  return (
    <div className="flex flex-col gap-2 bg-primary-100 dark:bg-background-700/20">
      <MaxWidthContainer className="group relative h-[250px] cursor-pointer sm:h-[400px] md:h-[500px] lg:h-[650px] xl:h-[685px] lt-lg:!p-0">
        {!ready && (
          <>
            <Image
              src="/transcribing-speech-with-vocalstack.webp"
              className="absolute object-contain"
              alt="Transcribing Speech to Text"
              fill
              onClick={() => setReady(true)}
            />
            <PlayCircleIcon
              onClick={() => setReady(true)}
              className="absolute inset-0 m-auto w-16 text-primary-500 opacity-50 transition-all group-hover:opacity-90 group-hover:scale-125 dark:text-white sm:w-24"
            />
          </>
        )}
        {ready && (
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/WAVG5XEnc68?autoplay=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Transcribing Speech to Text with VocalStack"
          ></iframe>
        )}
      </MaxWidthContainer>
    </div>
  );
};
