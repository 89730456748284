'use client';

import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FastAverageColor } from 'fast-average-color';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { TypeAnimation } from '../TypeAnimation';

import useMediaQuery from '@/hooks/useMediaQuery';
import tailwindConfig from '@/tailwind.config';
import { useTranslations } from '@/context/TranslationsContext';
import { BrowserMockup } from '@/components/widgets/BrowserMockup/BrowserMockup';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { GhostButton } from '@/components/Fields/Buttons';
import { Player } from '@/components/widgets/AudioPlayer/AudioPlayer';
import { Heading } from '@/components/Fields/Heading';
import { ChunksTimeline } from '@/components/transcription/TranscriptionOutput/ChunksTimeline';
import { Robot } from '@/components/widgets/Robot/Robot';
import { Translation } from '@/components/Translation';
import { useTranslation } from '@/hooks/useTranslation';
import { CountryFlag } from '@/components/widgets/CountryFlag/CountryFlag';
import { LocaleKey } from '@/constants/locales';
import { ExampleWithNoTranslations } from '@/data/examples/ExampleTypes';
import { loadTranslation } from '@/data/examples/ExampleDataClient';

type ExamplesInnerProps = {
  examples: ExampleWithNoTranslations[];
};

export const ExamplesClient = ({ examples }: ExamplesInnerProps) => {
  const sequences = {
    1: useTranslation(
      ['home', 'examples', 'sequence', 'one'],
      'Click the "play" button on any audio file above, and I will transcribe it for you using VocalStack platform.',
    ),
    2: useTranslation(
      ['home', 'examples', 'sequence', 'two'],
      'That was easy! Now let\'s add a translation. Click the "Translations" button bellow to see the available languages.',
    ),
    3: useTranslation(
      ['home', 'examples', 'sequence', 'three'],
      'Nice one! Why not jump to the VocalStack Dashboard and see what else you can do? It takes less than a minute to get started!',
    ),
    4: `${useTranslation(
      ['home', 'examples', 'sequence', 'four'],
      'Or you could just close the window and go back to your life',
    )} ¯\\_(ツ)_/¯`,
  };

  const [example, setExample] = useState<ExampleWithNoTranslations>();
  const [status, setStatus] = useState<'playing' | 'stopped' | 'preview' | 'paused'>('stopped');
  const [time, setTime] = useState<number>(0);
  const [robotText, setRobotText] = useState<string>(sequences[1]);
  const [exampleColors, setExampleColors] = useState<Record<string, string>>({});
  const { translations } = useTranslations();
  const robotRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mediumMediaQuery = useMediaQuery((tailwindConfig.theme?.extend?.screens as any)?.['lt-lg']?.raw);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const smallMediaQuery = useMediaQuery((tailwindConfig.theme?.extend?.screens as any)?.['lt-md']?.raw);

  const scroll = () => {
    const element = robotRef.current;
    if (!element) return;
    const smallWindow = smallMediaQuery || mediumMediaQuery;
    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const top = rect.top + scrollTop;
    const adjustedTop = top - (smallWindow ? 50 : 100);
    console.log({ top, adjustedTop, ref: robotRef.current });
    window.scrollTo({ top: adjustedTop, behavior: 'smooth' });
  };

  useEffect(() => {
    if ([sequences[1], sequences[4]].includes(robotText) && example && translations.length <= 1) {
      setRobotText(sequences[2]);
      scroll();
    }

    if (example && translations.length > 1 && robotText !== sequences[3]) {
      setRobotText(sequences[3]);
      scroll();
    }

    if (!example && robotText === sequences[2]) {
      setRobotText(sequences[4]);
      scroll();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [example, robotText, translations.length]);

  useEffect(() => {
    if (!example) return;
    const l = translations.filter((lang) => lang !== 'Detected') as LocaleKey[];
    for (const lang of l) {
      void loadTranslation(example, lang).then((val) => {
        setExample({ ...val });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [example?.id, translations]);

  return (
    <MaxWidthContainer className="flex flex-col gap-4 sm:gap-10">
      <Heading level={2} className="mx-auto font-thin">
        <Translation name="home.examples.try-it-out.title">Try it out</Translation>
      </Heading>
      <p className="mx-auto text-gray-400">
        <Translation name="home.examples.try-it-out.body">
          Try some examples to see it in action. VocalStack transcribes speech with proper punctuation and formatting.
        </Translation>
      </p>
      <Swiper
        slidesPerView={smallMediaQuery ? 1 : mediumMediaQuery ? 2 : 3}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="!grid grid-cols-1 !pb-10 [&_.swiper-pagination-bullet-active]:!bg-emerald-500 [&_.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)]:!bg-gray-300 [&_.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)]:!opacity-100 [&_.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)]:dark:!bg-gray-100"
        wrapperClass="cursor-grab"
      >
        {examples.map((e, i) => (
          <SwiperSlide key={i} style={{ width: '33%' }}>
            <PersonCard
              example={e}
              setExample={setExample}
              setStatus={setStatus}
              setTime={setTime}
              setExampleColors={setExampleColors}
              isSelected={example === e}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div ref={robotRef} className="flex justify-center gap-10">
        <Robot className="lt-md:w-10 lt-md:scale-50" speaking={status === 'playing'} />
        <div className="flex items-center justify-start">
          <div className="w-3 overflow-hidden">
            <div className="h-4 origin-bottom-right rounded-sm bg-emerald-300 rotate-45 transform dark:bg-emerald-700"></div>
          </div>
          <div className="my-6 w-64 flex-1 rounded-lg bg-emerald-300 p-4 dark:bg-emerald-700 sm:w-96">
            <TypeAnimation sequence={[robotText]} inline speed={40} preRenderFirstString={robotText === sequences[1]} />
            {robotText === sequences[3] && (
              <GhostButton className="mt-4">
                🚀{' '}
                <Translation name="home.button.get-started" nonDefaultLocaleText="Start Now">
                  Get Started
                </Translation>
              </GhostButton>
            )}
          </div>
        </div>
      </div>

      {example && ['playing', 'preview', 'paused'].includes(status) && (
        <div
          style={
            {
              '--dominant-color': exampleColors[example.id],
              '--dominant-color-opacity': `${exampleColors[example.id]}10`,
            } as React.CSSProperties
          }
        >
          <BrowserMockup
            className={classNames(
              'mb-10 sm:mb-20',
              `[&_.language-bar-bg]:bg-[var(--dominant-color)] [&_.language-bar-bg]:opacity-10 [&_.language-bar]:!relative [&_.language-bar]:!top-0`,
              '[&_.even-chunks-row-bg]:bg-[var(--dominant-color-opacity)]',
              '[&_.language-selector]:!ml-0',
            )}
            color={exampleColors[example.id]}
            onClose={() => {
              setExample(undefined);
              setStatus('stopped');
              // @ts-ignore
              document.querySelector('.rhap_play-status--playing .rhap_play-pause-button')?.click();
            }}
            onMinimize={() => setExample(undefined)}
          >
            <ChunksTimeline
              timeline={example.timeline}
              showLanguageSelector
              showLanguagesBar
              capTimeAt={['playing', 'paused'].includes(status) ? time : undefined}
              languagesLoading={[]}
            />
          </BrowserMockup>
        </div>
      )}
    </MaxWidthContainer>
  );
};

const PersonCard = ({
  example,
  setExample,
  setStatus,
  setTime,
  setExampleColors,
  isSelected,
}: {
  example: ExampleWithNoTranslations;
  isSelected?: boolean;
  setExample: Dispatch<SetStateAction<ExampleWithNoTranslations | undefined>>;
  setStatus: Dispatch<SetStateAction<'playing' | 'stopped' | 'paused' | 'preview'>>;
  setTime: Dispatch<SetStateAction<number>>;
  setExampleColors: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [dominantColor, setDominantColor] = useState<string>();

  useEffect(() => {
    const fac = new FastAverageColor();
    const src = imgRef.current?.src;
    if (!src) return;
    void fac.getColorAsync(src, { algorithm: 'dominant' }).then((result) => {
      setDominantColor(result.hex);
      setExampleColors((prev) => ({ ...prev, [example.id]: result.hex }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="person-card flex h-full select-none px-4" suppressHydrationWarning>
      <div
        className={classNames(
          'relative flex w-full gap-6 overflow-clip rounded-lg border-2 bg-sky-400/10 p-6 backdrop-blur-lg',
          {
            'dark:bg-transparent': dominantColor,
            'border-transparent': !isSelected,
            'border-emerald-400': isSelected,
          },
        )}
      >
        <div className="absolute inset-0 opacity-10 dark:opacity-40" style={{ backgroundColor: dominantColor }}></div>

        <div className="play-container relative z-10 h-20 w-20 shrink-0 cursor-pointer">
          <Player
            src={example.src}
            className="absolute -bottom-4 -right-4"
            onPlay={() => {
              setExample(example);
              setStatus('playing');
            }}
            onPause={() => {
              setStatus('paused');
            }}
            onEnded={() => {
              setStatus('preview');
            }}
            onSeeked={(time) => {
              setTime(time);
            }}
          />
          <Image
            ref={imgRef}
            src={example.img}
            className="h-inherit w-inherit aspect-square rounded-full hover:shadow"
            width={100}
            height={100}
            alt={`${example.name} by ${example.speaker}`}
            // @ts-ignore
            onClick={(e) => e.target.closest('.person-card').querySelector('.rhap_play-pause-button')?.click()}
          />
        </div>

        <div className="relative z-10 flex flex-col gap-3">
          <div className="flex gap-4">
            <div>
              <h3 className="text-xl font-bold lt-sm:text-sm">{example.name}</h3>
              <p className="flex items-center gap-1 font-light opacity-70 lt-sm:text-xs">{example.speaker}</p>
            </div>
          </div>

          <GhostButton
            className="relative z-10 mr-auto sm:mt-auto"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const ref: RefObject<any> = (window as any).PLAYING_REF;
              if (ref?.current?.audio.current) {
                ref.current.audio.current.pause();
              }
              setTimeout(() => {
                setExample(example);
                setStatus('preview');
              });
            }}
          >
            {example.countries.map((c, i) => (
              <CountryFlag key={i} code={c} className="relative -mt-0.5 w-3 shrink-0" aria-hidden="true" />
            ))}
            <Translation name="home.examples.button.transcribe">Transcribe</Translation>
          </GhostButton>
        </div>
      </div>
    </div>
  );
};
