import { InputHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

const primaryColorsCSS = 'bg-white dark:bg-white/10';

const ghostCSS =
  'bg-black/5 hover:bg-black/10 dark:bg-white/5 dark:hover:bg-white/10 focus-visible:outline-white border-gray-500/20 dark:border-white/10';

const borderColorsCSS = 'border-[1px] border-primary-200 dark:border-white/10';

const focusCSS = 'dark:focus-visible:border-primary-600';

const disabledCSS = 'disabled:opacity-50 disabled:cursor-not-allowed';

const otherCSS = twMerge(
  borderColorsCSS,
  focusCSS,
  'block w-full items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm justify-center tracking-wide transition-all cursor-pointer select-none',
  disabledCSS,
);

export const Text = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return <input type="text" {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} />;
};
export const TextGhost = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return <input type="text" {...props} className={twMerge(otherCSS, ghostCSS, props.className)} />;
};

export const Password = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return <input type="password" {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} />;
};

export const Email = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return <input type="email" {...props} className={twMerge(primaryColorsCSS, otherCSS, props.className)} />;
};

export const TextWithPrependedText = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={twMerge(primaryColorsCSS, otherCSS, props.className, 'flex gap-0 py-0 pr-0')}>
      <div
        onClick={(e) => ((e.target as HTMLDivElement).nextSibling as HTMLInputElement)?.focus()}
        className="select-none pr-0 tracking-wider opacity-50"
      >
        {props.title}
      </div>
      <Text {...props} className="border-none !bg-transparent px-0 tracking-wide" />
    </div>
  );
};

export const Radio = (props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input
      type="radio"
      {...props}
      className={twMerge(
        primaryColorsCSS,
        otherCSS,
        'w-5 accent-background-500 dark:accent-background-300',
        props.className,
      )}
    />
  );
};
