'use client';

import React, { useEffect, useId, useRef, useState } from 'react';

import classNames from 'classnames';

import { Translation } from '@/components/Translation';
import { HeroIconsIcon, IconComponent } from '@/components/icons/icons';

type Icon = IconComponent | HeroIconsIcon;

type ToggleComponentProps = {
  value1: string;
  value2: string;
  activeValue: string;
  onChange: (value: string) => void;
  size?: number;
  theme?: 'auto' | 'light' | 'dark';
  icons?: [Icon, Icon];
  className?: string;
  hoverSelect?: boolean;
  disabled?: boolean;
};

export const Toggle = (props: ToggleComponentProps) => {
  const { className, value1, value2, activeValue, onChange, size = 8, theme = 'auto', hoverSelect, disabled } = props;
  const id = useId().replaceAll(':', '');
  const initialActiveValue = useRef<string>(activeValue);
  const toggle1Ref = useRef<HTMLSpanElement>(null);
  const toggle2Ref = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [translateX, setTranslateX] = useState<number>(4);
  const [toggleWidth, setToggleWidth] = useState<number | undefined>();
  const [isVisible, setIsVisible] = useState(false);
  const [hasTransitionAnimations, setHasTransitionAnimations] = useState(false);
  const [Icon1, Icon2] = props.icons || [];

  const resize = () => {
    if (toggle1Ref.current && toggle2Ref.current && containerRef.current) {
      const el = (activeValue === value1 ? toggle1Ref : toggle2Ref).current;
      const spaceToMove = Number(toggle1Ref.current?.offsetWidth);
      setTranslateX(activeValue === value2 ? spaceToMove + 28 : 4);
      setToggleWidth(Number(el?.offsetWidth) + 24);
    }
  };

  useEffect(resize, [activeValue, value1, value2, isVisible]);

  useEffect(() => {
    if (isVisible) {
      resize();
      setTimeout(() => setHasTransitionAnimations(true), 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (containerRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsVisible(true);
            resize();
            observer.disconnect();
          }
        },
        { threshold: 0.1 },
      );
      observer.observe(containerRef.current);

      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        'group relative isolate flex select-none rounded-full text-center text-xs tracking-wide',
        `h-${size} leading-${size}`,
        {
          'bg-background-100 text-gray-900': theme === 'light',
          'bg-white/10 text-white': theme === 'dark',
          'bg-background-100 text-gray-900 dark:bg-white/10 dark:text-white': theme === 'auto',
        },
        className,
      )}
      onClick={() => !hoverSelect && !disabled && onChange(activeValue === value1 ? value2 : value1)}
    >
      <div className="relative z-10 flex cursor-pointer gap-x-6 px-[16px] font-semibold">
        <span
          ref={toggle1Ref}
          id="toggle1"
          className={classNames('flex items-center gap-1', {
            'opacity-60': value1 !== activeValue,
            'transition-all': hasTransitionAnimations,
          })}
          onMouseEnter={() => hoverSelect && !disabled && activeValue !== value1 && onChange(value1)}
        >
          {Icon1 && <Icon1 className="h-5 w-5" />}
          <Translation name={`toggle.${value1}`}>{value1}</Translation>
        </span>
        <span
          ref={toggle2Ref}
          className={classNames('flex items-center gap-1', {
            'opacity-60': value2 !== activeValue,
            'transition-all': hasTransitionAnimations,
          })}
          onMouseEnter={() => hoverSelect && !disabled && activeValue !== value2 && onChange(value2)}
        >
          {Icon2 && <Icon2 className="h-5 w-5" />}
          <Translation name={`toggle.${value2}`}>{value2}</Translation>
        </span>
      </div>
      <span
        suppressHydrationWarning
        id={id}
        style={{
          transform: `translateX(${translateX}px)`,
          width: `${toggleWidth}px`,
        }}
        className={classNames(
          'toggle-slider',
          'absolute cursor-pointer rounded-full px-[12px] font-semibold tracking-wide text-transparent shadow',
          `inset-y-1 leading-${size - 2}`,
          {
            'bg-white': theme === 'light',
            'bg-primary-400/7': theme === 'dark',
            '!bg-white dark:!bg-primary-400/70': theme === 'auto',
            'transition-all duration-300': hasTransitionAnimations,
          },
        )}
      >
        <Translation name={`toggle.${activeValue === value1 ? value1 : value2}`}>{activeValue}</Translation>
      </span>
      {initialActiveValue.current === value2 && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            document.getElementById('${id}').style.transform = 'translateX('+(document.getElementById('toggle1').offsetWidth + 28)+'px)';
          `,
          }}
        />
      )}
    </div>
  );
};
