'use client';

import classNames from 'classnames';
import { ClerkLoading, SignUpButton, SignedIn, SignedOut, useAuth } from '@clerk/nextjs';
import { CursorArrowRaysIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

import { Prices } from '@/constants/pricingData';
import { ButtonAnchor, GhostOnDarkAnchor, GhostButton } from '@/components/Fields/Buttons';
import { Translation } from '@/components/Translation';
import { useBilling } from '@/context/BillingContext';

type PricingButtonProps = {
  plan: Prices[keyof Prices];
  className?: string;
  location: 'cards' | 'table';
  children?: React.ReactNode;
};

export const PricingButton = ({ plan, className, location, children }: PricingButtonProps) => {
  const { isSignedIn } = useAuth();
  const { billing, premium_yearly_payment_link, premium_monthly_payment_link, billingCycle } = useBilling();

  const signedInUserHasButtons = premium_yearly_payment_link || premium_monthly_payment_link;
  const { label } = plan;
  const meta_name = billing?.active_plan.meta_name;

  const clearBilling = () => {
    localStorage.removeItem('billing');
    document.cookie = 'LAST_BILLING_UPDATE=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const Btn = location === 'cards' ? GhostOnDarkAnchor : GhostButton;

  if (signedInUserHasButtons && meta_name && label === 'Hobby' && meta_name === 'Premium') {
    return <GhostButton className="invisible mx-auto mt-auto h-11 lg:mx-0" />;
  }

  if (label === billing?.active_plan.meta_name) {
    return (
      <GhostButton
        className={classNames(
          'pointer-events-none mt-auto h-11 touch-none font-extralight italic lt-lg:mx-auto',
          {
            '!bg-gray-300/50 dark:!bg-gray-700/50': label === 'Hobby',
            '!bg-green-300 dark:!bg-green-700': label !== 'Hobby',
          },
          className,
        )}
      >
        <Translation name="pricing.button.your-current-plan">Your current plan</Translation>
      </GhostButton>
    );
  }

  if (label === 'Hobby') {
    const btn = (signedIn: boolean) => (
      <Btn href={signedIn === false ? undefined : '/dashboard'} className={className}>
        <CursorArrowRaysIcon className="inline h-6 w-6" aria-hidden="true" />
        {children || <Translation name="pricing.button.get-this-plan">Get this plan</Translation>}
      </Btn>
    );
    return (
      <>
        <SignedIn>{btn(true)}</SignedIn>
        <SignedOut>
          <SignUpButton mode="modal" forceRedirectUrl="/dashboard">
            {btn(false)}
          </SignUpButton>
        </SignedOut>
        <ClerkLoading>{!isSignedIn && btn(false)}</ClerkLoading>
      </>
    );
  }
  if (label === 'Premium') {
    const btn = (signedIn: boolean) => (
      <ButtonAnchor
        onClick={clearBilling}
        href={
          !signedIn
            ? undefined
            : billingCycle === 'Monthly'
              ? premium_monthly_payment_link
              : premium_yearly_payment_link
        }
        className={className}
      >
        <CursorArrowRaysIcon className="inline h-6 w-6" aria-hidden="true" />
        {children || <Translation name="pricing.button.get-this-plan">Get this plan</Translation>}
      </ButtonAnchor>
    );
    return (
      <>
        <SignedIn>{btn(true)}</SignedIn>
        <SignedOut>
          <SignUpButton mode="modal" forceRedirectUrl="/dashboard">
            {btn(false)}
          </SignUpButton>
        </SignedOut>
        <ClerkLoading>{!isSignedIn && btn(false)}</ClerkLoading>
      </>
    );
  }
  if (label === 'Enterprise') {
    return (
      <Btn href="/contact" className={className}>
        <ChatBubbleLeftRightIcon className="inline h-6 w-6" aria-hidden="true" />
        <Translation name="pricing.button.lets-talk">{"Let's talk"}</Translation>
      </Btn>
    );
  }
  return null;
};
