import classNames from 'classnames';

import './pulse.scss';

type PulseAnimationProps = {
  className?: string;
};

export const PulseAnimation = (props: PulseAnimationProps) => (
  <div className={classNames('flex items-center', props.className)}>
    <div className="connection">
      <span className="point"></span>
      <span className="point"></span>
      <span className="point"></span>
      <span className="point"></span>
      <span className="point lt-lg:!hidden"></span>
      <span className="point lt-md:!hidden lt-lg:!hidden"></span>
    </div>
  </div>
);
