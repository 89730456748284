import classNames from 'classnames';
import { DeviceFrameset } from 'react-device-frameset';

import { GlowingBorder } from '@/components/widgets/GlowingBorder/GlowingBorder';

import './deviceFrame.scss';

export const colors = ['red', 'blue', 'yellow', 'green', 'purple', 'pink', 'indigo'] as const;

export type Color = (typeof colors)[number];

export type DeviceType = 'laptop' | 'tablet' | 'mobile';

require('react-device-frameset/styles/marvel-devices.min.css');

type DeviceFrameProps = {
  className?: string;
  device: DeviceType;
  children: React.ReactNode;
  color?: Color;
  glow?: boolean;
  shadow?: boolean;
  noBackground?: boolean;
};

function colorClass(color?: Color) {
  if (!color) return '[&>div]:!bg-gray-500';
  if (color === 'red') return `[&>div]:!bg-red-500`;
  if (color === 'blue') return `[&>div]:!bg-blue-500`;
  if (color === 'yellow') return `[&>div]:!bg-yellow-500`;
  if (color === 'green') return `[&>div]:!bg-green-500`;
  if (color === 'purple') return `[&>div]:!bg-purple-500`;
  if (color === 'pink') return `[&>div]:!bg-pink-500`;
  if (color === 'indigo') return `[&>div]:!bg-indigo-500`;
}

export const DeviceFrame = ({ className, device, color, children, glow, shadow, noBackground }: DeviceFrameProps) => (
  <GlowingBorder
    enabled={glow}
    className={
      device === 'laptop'
        ? 'after:h-full after:!rounded-[40px]'
        : device === 'tablet'
          ? 'after:!rounded-[50px]'
          : 'after:!rounded-[70px]'
    }
  >
    <div
      className={classNames(
        className,
        colorClass(color),
        // '[&>div]:!shadow-none [&>div]:dark:!shadow-[inset_0_0_11px_0_#000]',
        'flex [&>div]:mx-auto',
        {
          'sm:[&>div]:![box-shadow:_0px_0px_300px_transparent]': !shadow,
          'sm:[&>div]:![box-shadow:_0px_0px_300px_#0ea5e950] sm:[&>div]:hover:![box-shadow:_0px_0px_300px_#0ea5e970]':
            shadow,
          '[&_.marvel-device]:before:!bg-white [&_.marvel-device]:before:dark:!bg-background-800': noBackground,
        },
      )}
    >
      <DeviceFrameset
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        device={(device === 'laptop' ? 'MacBook Pro' : device === 'tablet' ? 'iPad Mini' : 'iPhone X') as any}
      >
        <div className="absolute inset-0 bg-transparent">
          <div className={classNames('relative h-full')}>{children}</div>
        </div>
      </DeviceFrameset>
    </div>
  </GlowingBorder>
);
