import React from 'react';

import FlagIconFactory from 'react-flag-icon-css';
import 'flag-icon-css/css/flag-icon.min.css';
import classNames from 'classnames';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

type CountryFlagProps = {
  code: string;
  className?: string;
  rounded?: boolean;
  pulsate?: boolean;
};

export const CountryFlag = ({ className, code, rounded, pulsate }: CountryFlagProps) =>
  code && (
    <FlagIcon
      aria-hidden="true"
      code={code.toLocaleLowerCase()}
      className={classNames(className, {
        'aspect-square rounded-full !bg-cover': rounded,
        'before:absolute before:inset-1 before:-z-10 before:animate-pulse before:rounded-full before:shadow-[0_0_10px_2px_rgba(0,0,0,0.75)] before:will-change-[box-shadow] before:scale-125 before:dark:shadow-[0_0_10px_2px_rgba(255,255,255,0.75)]':
          pulsate,
      })}
    />
  );
