import { z } from 'zod';

// does not support: "mi"

// prettier-ignore
// ISO 639-1
export const zLocale = z.enum(["af", "ar", "hy", "az", "be", "bs", "bg", "ca", "zh", "hr", "cs", "da", "nl", "en", "et", "fi", "fr", "gl", "de", "el", "he", "hi", "hu", "is", "id", "it", "ja", "kn", "kk", "ko", "lv", "lt", "mk", "ms", "mr", "ne", "no", "fa", "pl", "pt", "ro", "ru", "sr", "sk", "sl", "es", "sw", "sv", "tl", "ta", "th", "tr", "uk", "ur", "vi", "cy"]);

export const zLanguage = z.union([zLocale, z.literal('Detected')]);

export type LocaleKey = z.infer<typeof zLocale>;

export type Locale = { id: LocaleKey; title: string; country: string; actualTitle: string };

export type Language = z.infer<typeof zLanguage>;

// id - ISO 639-1
// country - ISO 3166-1-alpha-2 (https://www.iso.org/obp/ui/#search)
export const locales: Locale[] = [
  { id: 'af', title: 'Afrikaans', country: 'ZA', actualTitle: 'Afrikaans' },
  { id: 'ar', title: 'Arabic', country: 'SA', actualTitle: 'العربية' },
  { id: 'hy', title: 'Armenian', country: 'AM', actualTitle: 'Հայերեն' },
  { id: 'az', title: 'Azerbaijani', country: 'AZ', actualTitle: 'Azərbaycan' },
  { id: 'be', title: 'Belarusian', country: 'BY', actualTitle: 'Беларуская' },
  { id: 'bs', title: 'Bosnian', country: 'BA', actualTitle: 'Bosanski' },
  { id: 'bg', title: 'Bulgarian', country: 'BG', actualTitle: 'Български' },
  { id: 'ca', title: 'Catalan', country: 'ES', actualTitle: 'Català' },
  { id: 'zh', title: 'Chinese', country: 'CN', actualTitle: '中文' },
  { id: 'hr', title: 'Croatian', country: 'HR', actualTitle: 'Hrvatski' },
  { id: 'cs', title: 'Czech', country: 'CZ', actualTitle: 'Čeština' },
  { id: 'da', title: 'Danish', country: 'DK', actualTitle: 'Dansk' },
  { id: 'nl', title: 'Dutch', country: 'NL', actualTitle: 'Nederlands' },
  { id: 'en', title: 'English', country: 'GB', actualTitle: 'English' },
  { id: 'et', title: 'Estonian', country: 'EE', actualTitle: 'Eesti' },
  { id: 'fa', title: 'Farsi', country: 'IR', actualTitle: 'فارسی' },
  { id: 'fi', title: 'Finnish', country: 'FI', actualTitle: 'Suomi' },
  { id: 'fr', title: 'French', country: 'FR', actualTitle: 'Français' },
  { id: 'gl', title: 'Galician', country: 'ES', actualTitle: 'Galego' },
  { id: 'de', title: 'German', country: 'DE', actualTitle: 'Deutsch' },
  { id: 'el', title: 'Greek', country: 'GR', actualTitle: 'Ελληνικά' },
  { id: 'he', title: 'Hebrew', country: 'IL', actualTitle: 'עברית' },
  { id: 'hi', title: 'Hindi', country: 'IN', actualTitle: 'हिन्दी' },
  { id: 'hu', title: 'Hungarian', country: 'HU', actualTitle: 'Magyar' },
  { id: 'is', title: 'Icelandic', country: 'IS', actualTitle: 'Íslenska' },
  { id: 'id', title: 'Indonesian', country: 'ID', actualTitle: 'Indonesia' },
  { id: 'it', title: 'Italian', country: 'IT', actualTitle: 'Italiano' },
  { id: 'ja', title: 'Japanese', country: 'JP', actualTitle: '日本語' },
  { id: 'kn', title: 'Kannada', country: 'IN', actualTitle: 'ಕನ್ನಡ' },
  { id: 'kk', title: 'Kazakh', country: 'KZ', actualTitle: 'Қазақ' },
  { id: 'ko', title: 'Korean', country: 'KR', actualTitle: '한국어' },
  { id: 'lv', title: 'Latvian', country: 'LV', actualTitle: 'Latviešu' },
  { id: 'lt', title: 'Lithuanian', country: 'LT', actualTitle: 'Lietuvių' },
  { id: 'mk', title: 'Macedonian', country: 'MK', actualTitle: 'Македонски' },
  { id: 'ms', title: 'Malay', country: 'MY', actualTitle: 'Melayu' },
  { id: 'mr', title: 'Marathi', country: 'IN', actualTitle: 'मराठी' },
  // { id: 'mi', title: 'Maori', country: 'NZ', actualTitle: 'Māori' },
  { id: 'ne', title: 'Nepali', country: 'NP', actualTitle: 'नेपाली' },
  { id: 'no', title: 'Norwegian', country: 'NO', actualTitle: 'Norsk' },
  { id: 'pl', title: 'Polish', country: 'PL', actualTitle: 'Polski' },
  { id: 'pt', title: 'Portuguese', country: 'PT', actualTitle: 'Português' },
  { id: 'ro', title: 'Romanian', country: 'RO', actualTitle: 'Română' },
  { id: 'ru', title: 'Russian', country: 'RU', actualTitle: 'Русский' },
  { id: 'sr', title: 'Serbian', country: 'RS', actualTitle: 'Српски' },
  { id: 'sk', title: 'Slovak', country: 'SK', actualTitle: 'Slovenčina' },
  { id: 'sl', title: 'Slovenian', country: 'SI', actualTitle: 'Slovensko' },
  { id: 'es', title: 'Spanish', country: 'ES', actualTitle: 'Español' },
  { id: 'sw', title: 'Swahili', country: 'KE', actualTitle: 'Kiswahili' },
  { id: 'sv', title: 'Swedish', country: 'SE', actualTitle: 'Svenska' },
  { id: 'tl', title: 'Tagalog', country: 'PH', actualTitle: 'Tagalog' },
  { id: 'ta', title: 'Tamil', country: 'IN', actualTitle: 'தமிழ்' },
  { id: 'th', title: 'Thai', country: 'TH', actualTitle: 'ไทย' },
  { id: 'tr', title: 'Turkish', country: 'TR', actualTitle: 'Türkçe' },
  { id: 'uk', title: 'Ukrainian', country: 'UA', actualTitle: 'Українська' },
  { id: 'ur', title: 'Urdu', country: 'PK', actualTitle: 'اردو' },
  { id: 'vi', title: 'Vietnamese', country: 'VN', actualTitle: 'Việt' },
  { id: 'cy', title: 'Welsh', country: 'GB', actualTitle: 'Cymraeg' },
];

export const localeKeys: LocaleKey[] = locales.map(({ id }) => id);

export const mainLocaleKey: LocaleKey = 'en';

export const SUGGESTED_LOCALE_COOKIE = 'SUGGESTED_LOCALE';

export const LOCALE_COOKIE = 'LOCALE_KEY';

export const TRANSLATIONS_COOKIE = 'TRANSLATIONS';
