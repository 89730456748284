/* eslint-disable @next/next/no-img-element */

import { EnvelopeIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { FaGithub, FaYoutube, FaLinkedin } from 'react-icons/fa';

import { GhostAnchor, GhostButton } from './Fields/Buttons';
import { Heading } from './Fields/Heading';
import { Email } from './Fields/Input';
import { Label } from './Fields/Label';
import { MaxWidthContainer } from './Layouts/MaxWidthContainer';
import { Translation } from './Translation';
import { PricingButton } from './Pricing/PricingButton';
import { IconComponent } from './icons/icons';

import { getLocaleSpecificUrl } from '@/utils/locale';
import { LocaleKey } from '@/constants/locales';
import { prices } from '@/constants/pricingData';

type FooterProps = {
  localeKey: LocaleKey;
  hideFooter?: boolean;
};

export const SocialIcons = () => (
  <div className="order-1 flex items-center justify-center gap-10 lt-md:py-6">
    <SocialBtn url="https://www.linkedin.com/company/vocalstack" Icon={FaLinkedin} />
    <SocialBtn url="https://www.youtube.com/@VocalStack" Icon={FaYoutube} />
    <SocialBtn url="https://github.com/vocalstack/js-sdk" Icon={FaGithub} />
  </div>
);

const SocialBtn = ({ url, Icon }: { url: string; Icon: IconComponent }) => (
  <a target="_blank" href={url} className="cursor-pointer transition-all hover:scale-110">
    <Icon className="h-6 w-6" />
  </a>
);

export const Footer = (props: FooterProps) => (
  <footer className="relative mt-20">
    {!props.hideFooter && (
      <div className="relative py-10 sm:py-20 lg:gap-20">
        <div className="z-1 absolute inset-0 bg-pattern-1 opacity-10 transition-colors dark:opacity-5"></div>
        <div className="relative z-10 flex flex-col gap-10 sm:gap-20">
          <FindOutMore {...props} />
          <StayInTheLoop />
        </div>
      </div>
    )}
    <MaxWidthContainer className="flex py-6 text-black/50 dark:text-white/50 md:items-center md:justify-between lt-md:flex-col">
      <div className="mt-8 md:mt-0 lt-md:order-10">
        <p className="text-center text-sm leading-5">&copy; Copyright © {new Date().getFullYear()}</p>
      </div>
      <SocialIcons />
      <div className="order-1 flex justify-center gap-5 lt-sm:text-sm">
        <a href={getLocaleSpecificUrl(props.localeKey, '/privacy-policy')}>
          <Translation name="footer.privacy-policy">Privacy Policy</Translation>
        </a>
        <a href={getLocaleSpecificUrl(props.localeKey, '/terms-of-service')}>
          <Translation name="footer.tos">Terms of Service</Translation>
        </a>
      </div>
    </MaxWidthContainer>
  </footer>
);

const FindOutMore = ({ localeKey }: FooterProps) => (
  <MaxWidthContainer className="flex flex-col gap-10 sm:gap-20">
    <Heading level={2} className="mx-auto font-thin">
      <Translation name="home.more.title">Ready to find out more?</Translation>
    </Heading>
    <div className="flex w-full gap-4 sm:gap-20">
      <div className="flex w-1/2 flex-col gap-4">
        <Heading level={3} className="block">
          <Translation name="home.try.title">Try it out yourself</Translation>
        </Heading>
        <p className="sm:text-balance font-extralight lt-sm:text-center lt-sm:text-sm">
          <Translation name="home.try.body">
            Create a free account to get instant access to the VocalStack Dashboard. It only takes a few seconds to get
            started.
          </Translation>
        </p>
        <PricingButton plan={prices.hobby} location="table" className="mr-auto mt-auto lt-sm:mx-auto">
          <Translation name="home.button.get-started" nonDefaultLocaleText="Start Now">
            Get Started
          </Translation>
        </PricingButton>
      </div>
      <div className="flex w-1/2 flex-col gap-4">
        <Heading level={3} className="block">
          <Translation name="home.demo.title">Request a Demo</Translation>
        </Heading>
        <p className="text-balance font-extralight lt-sm:text-center lt-sm:text-sm">
          <Translation name="home.demo.body">
            Still unsure if VocalStack is right for you or your business? Get in touch for a personalized tour of
            VocalStack.
          </Translation>
        </p>
        <GhostAnchor href={getLocaleSpecificUrl(localeKey, '/contact')} className="mr-auto mt-auto lt-sm:mx-auto">
          <ChatBubbleLeftRightIcon className="w-4" />
          <Translation name="home.demo.button">Request a Demo</Translation>
        </GhostAnchor>
      </div>
    </div>
  </MaxWidthContainer>
);

const StayInTheLoop = () => (
  <MaxWidthContainer className="flex w-full justify-between gap-6 lg:gap-20 lt-sm:flex-col">
    <div className="sm:w-1/2 lt-sm:order-2">
      <div className="flex flex-col gap-2 lt-sm:mx-auto lt-sm:max-w-lg">
        <Label>
          E-mail
          <Email className="bg-white/80 dark:border-white/30 dark:bg-background-900/50" />
        </Label>
        <p className="text-balance text-xs font-extralight">
          <Translation name="home.newsletter.body">
            VocalStack requires your contact information to communicate with you about product updates. You can opt out
            of these communications at any time. For more details, please see the Privacy Policy.
          </Translation>
        </p>
      </div>
      <GhostButton className="mt-4 lt-sm:mx-auto lt-sm:flex">
        <EnvelopeIcon className="w-4" />
        <Translation name="home.newsletter.button">Subscribe to Newsletter</Translation>
      </GhostButton>
    </div>
    <div className="flex flex-col gap-6 sm:w-1/2">
      <Heading level={2} className="text-3xl font-thin sm:text-4xl lg:text-6xl lt-sm:justify-center lt-sm:text-center">
        <Translation name="home.loop.title" nonDefaultLocaleText="Follow updates">
          Stay in the Loop
        </Translation>
      </Heading>
      <p className="font-extralight lt-sm:mx-auto lt-sm:max-w-lg lt-sm:text-center lt-sm:text-sm">
        <Translation name="home.loop.body">
          {`Subscribe with your email to receive the latest release notes and
          product updates. We promise to keep emails to a minimum. No one likes spam email!`}
        </Translation>
      </p>
    </div>
  </MaxWidthContainer>
);
