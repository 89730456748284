'use client';

import { useEffect } from 'react';

import { useUser } from '@clerk/clerk-react';
import { H } from '@highlight-run/next/client';

export function CustomHighlightStart() {
  const { user } = useUser();

  useEffect(() => {
    // null is not logged in
    // undefined is loading
    if (typeof user === 'undefined') {
      return;
    }

    if (user?.publicMetadata?.developer) {
      console.log('Developer detected - skipping Highlight');
      return;
    }

    const ignoredPaths = ['/polyglot/demo'];
    const ignoredDomains = ['polyglot.vocalstack.com'];
    const currentPath = window.location.pathname;

    if (ignoredPaths.some((path) => currentPath === path || currentPath.startsWith(`${path}/`))) {
      return;
    }

    if (ignoredDomains.some((domain) => window.location.hostname.includes(domain))) {
      return;
    }

    H.init('ng2x120d', {
      serviceName: 'vocalstack-frontend',
      environment: process.env.NODE_ENV,
      tracingOrigins: true,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [],
      },
    });

    if (user?.id) {
      H.identify(user.id, {
        email: user.emailAddresses?.[0]?.emailAddress,
        name: String(user.fullName),
      });
    }
  }, [user]);

  return null;
}
