import { moneyValue } from '@/utils/money';

export type PricingDataItem = {
  free_hours: number;
  prerecorded_rate: number;
  live_rate: number;
  monthly_price: number;
  yearly_price: number;
};

type PricingData = Record<'hobby' | 'premium', PricingDataItem>;

export const pricingData: PricingData = {
  hobby: {
    free_hours: 8,
    prerecorded_rate: 0.7,
    live_rate: 1.6,
    monthly_price: 0,
    yearly_price: 0,
  },
  premium: {
    free_hours: 40,
    prerecorded_rate: 0.35,
    live_rate: 0.8,
    monthly_price: 50,
    yearly_price: 480,
  },
};

export type PlanKey = 'hobby' | 'premium' | 'enterprise';
export type FeatureKey =
  | 'plan-price'
  | 'dashboard-polyglot'
  | 'dashboard-upload-from-file'
  | 'dashboard-import-from-url'
  | 'dashboard-record-from-mic'
  | 'dashboard-translations'
  | 'dashboard-export'
  // | 'features-video-generation'
  // | 'features-live-transcription-app'
  // | 'features-web-ui-widgets'
  | 'developers-api'
  | 'developers-database'
  | 'developers-managed-services'
  | 'developers-rate-limit'
  | 'developers-start-up-type'
  | 'polyglot-transcribe-from-mic'
  | 'polyglot-transcribe-from-livestream'
  | 'polyglot-view-past-transcriptions'
  | 'polyglot-share-via-public-url'
  | 'polyglot-translate-via-public-url'
  | 'polyglot-password-protected'
  | 'polyglot-scheduled-livestream-transcription'
  | 'ai-language-support'
  | 'ai-language-detection'
  | 'ai-segmentation'
  | 'ai-summarization'
  | 'ai-timestamps'
  | 'ai-alignment'
  | 'ai-diarization'
  | 'transcription-free-hours'
  | 'transcription-prerecorded'
  | 'transcription-live'
  | 'support-contact'
  | 'support-sla';

export type Features = {
  [key in FeatureKey]: {
    label: string;
    group: string;
    description: string;
  };
};

export type Prices = {
  [key in PlanKey]: {
    label: string;
    description: string;
    featured?: boolean;
    monthly_price?: number;
    yearly_price?: number;
    highlights: string[];
    features: {
      [key in FeatureKey]:
        | boolean
        | string
        | { free: number }
        | { rate: number }
        | { start: number; end: number }
        | { custom: true };
    };
  };
};

export const features: Features = {
  // Cost
  'plan-price': {
    label: 'Price',
    group: 'Price',
    description: 'How much it costs to use this plan.',
  },
  // Transcriptions
  'transcription-free-hours': {
    label: 'Free Transcription Hours',
    group: 'Transcriptions',
    description: 'The number of free transcription hours included in your plan each month.',
  },
  'transcription-prerecorded': {
    label: 'Pre-recorded Transcriptions',
    group: 'Transcriptions',
    description:
      'Pre-recorded transcription refers to the process of transcribing audio that has been previously recorded. It can be uploaded as an audio file and transcribed at a later time, making it suitable for podcasts, interviews, videos, and other recorded content.',
  },
  'transcription-live': {
    label: 'Live Transcription',
    group: 'Transcriptions',
    description:
      'Live transcription refers to the process of transcribing audio in real time as it is being spoken. This is useful for live streams, podcasts, events, meetings, lectures, and other scenarios where immediate transcription (and possibly translation) is required',
  },
  // Features
  // 'features-live-transcription-app': {
  //   label: 'Live Presentation with Translation',
  //   group: 'Power Features',
  //   description:
  //     'Embed a live transcription widget on your website, phone or tablet. Translate the transcription to a different language in real-time.',
  // },
  // 'features-web-ui-widgets': {
  //   label: 'Web UI Widgets',
  //   group: 'Power Features',
  //   description: 'Embed widgets showing your transcription results on your website, phone or tablet.',
  // },
  // 'features-video-generation': {
  //   label: 'Video Generation',
  //   group: 'Power Features',
  //   description: 'Generate a video with subtitles and AI generated imagery from your transcription output.',
  // },
  // Developers
  'developers-api': {
    label: 'API Access',
    group: 'Developers',
    description:
      'Access to the VocalStack API for programmatic access to the same services available through the UI / Dashboard.',
  },
  'developers-database': {
    label: 'Database Access',
    group: 'Developers',
    description: 'Direct access to the your dedicated VocalStack database for querying data.',
  },
  'developers-managed-services': {
    label: 'Managed Services',
    group: 'Developers',
    description: 'Managed services and custom development for your VocalStack platform-as-a-service implementation.',
  },
  'developers-rate-limit': {
    label: 'Transcription Rate Limit',
    group: 'Developers',
    description: 'The number of concurrent sessions you can run concurrently.',
  },
  'developers-start-up-type': {
    label: 'Server Start',
    group: 'Developers',
    description: 'How fast the server boots up.',
  },
  // Dashboard
  'dashboard-upload-from-file': {
    label: 'Transcribe Audio from Uploaded File',
    group: 'Dashboard',
    description: 'Upload an audio file from your local device and transcribe it into text.',
  },
  'dashboard-import-from-url': {
    label: 'Transcribe Audio from URL',
    group: 'Dashboard',
    description: 'Import an audio file from a URL and transcribe it into text.',
  },
  'dashboard-record-from-mic': {
    label: 'Transcribe Audio from from Microphone',
    group: 'Dashboard',
    description: 'Record audio directly from your device and transcribe it into text.',
  },
  'dashboard-export': {
    label: 'Export Subtitles and Files',
    group: 'Dashboard',
    description:
      'Export your transcriptions (with translations) to Excel or PDF documents. Generate subtitles for your transcription output.',
  },
  'dashboard-translations': {
    label: 'Translate Transcriptions',
    group: 'Dashboard',
    description: 'Translate your transcription output to a different language.',
  },
  'dashboard-polyglot': {
    label: 'Polyglot',
    group: 'Dashboard',
    description:
      'Use the Dashboard to turn your phone or device into a powerful tool to present live speech in real-time and in any language.',
  },

  // Polyglot
  'polyglot-transcribe-from-mic': {
    label: 'Transcribe from Microphone',
    group: 'Polyglot',
    description: 'Transcribe audio from your microphone in real-time.',
  },
  'polyglot-transcribe-from-livestream': {
    label: 'Transcribe from Live Stream',
    group: 'Polyglot',
    description: 'Transcribe audio from a live stream in real-time.',
  },
  'polyglot-share-via-public-url': {
    label: 'Real-Time Transcriptions via Public URL',
    group: 'Polyglot',
    description: 'Share your transcription via a public URL.',
  },
  'polyglot-translate-via-public-url': {
    label: 'Real-Time Translations via Public URL',
    group: 'Polyglot',
    description: 'Share your translation via a public URL.',
  },
  'polyglot-view-past-transcriptions': {
    label: 'Historical Transcriptions via Pubic URL',
    group: 'Polyglot',
    description: 'View past transcriptions and translations.',
  },
  'polyglot-password-protected': {
    label: 'Enable Password Protection',
    group: 'Polyglot',
    description: 'Protect your transcription and translation with a password for private sharing.',
  },
  'polyglot-scheduled-livestream-transcription': {
    label: 'Scheduled Livestream Transcriptions',
    group: 'Polyglot',
    description: 'Schedule a live stream transcription for a future event.',
  },
  // AI Model
  'ai-language-support': {
    label: 'Language Support',
    group: 'AI Enhancements',
    description:
      'Support for over 57 languages, including different dialects and accents. Supported languages include: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and Welsh.',
  },
  'ai-language-detection': {
    label: 'Automatic Language Detection',
    group: 'AI Enhancements',
    description: 'Automatically detect the language of your audio and transcribes in the correct language.',
  },
  'ai-segmentation': {
    label: 'Paragraph Segmentation',
    group: 'AI Enhancements',
    description: 'Segment your transcription into semantically meaningful paragraphs.',
  },
  'ai-summarization': {
    label: 'Summarization',
    group: 'AI Enhancements',
    description: 'Summarize your transcription into a single paragraph.',
  },
  'ai-timestamps': {
    label: 'Word-Level Time Stamps',
    group: 'AI Enhancements',
    description: 'Include time stamps for your transcription output.',
  },
  'ai-alignment': {
    label: 'Word-Level Alignment',
    group: 'AI Enhancements',
    description: 'Align words with their corresponding audio segments in the transcription output.',
  },
  'ai-diarization': {
    label: 'Speaker Diarization',
    group: 'AI Enhancements',
    description: 'Automatically separate speakers in the transcription output.',
  },
  // Support
  'support-contact': {
    label: 'Help & Support',
    group: 'Support',
    description: 'The level of technical support you receive with this plan.',
  },
  'support-sla': {
    label: 'SLA',
    group: 'Support',
    description: 'Service Level Agreement for guaranteed response times.',
  },
};

export const prices: Prices = {
  hobby: {
    label: 'Hobby',
    description: 'Get started quickly with no commitment',
    monthly_price: 0,
    yearly_price: 0,
    highlights: [
      `${pricingData.hobby.free_hours} hours of free transcriptions each month`,
      'Same high quality transcription as in the paid plans',
      'No credit card required for the free monthly transcriptions',
    ],
    features: {
      'plan-price': 'no recurring costs',
      'transcription-free-hours': { free: pricingData.hobby.free_hours },
      'transcription-prerecorded': { rate: pricingData.hobby.prerecorded_rate },
      'transcription-live': { rate: pricingData.hobby.live_rate },
      'dashboard-upload-from-file': true,
      'dashboard-import-from-url': true,
      'dashboard-record-from-mic': true,
      'dashboard-translations': true,
      'dashboard-polyglot': true,
      'dashboard-export': true,
      'developers-api': true,
      'developers-database': false,
      'developers-managed-services': false,
      'developers-rate-limit': 'max 3 concurrent sessions',
      'developers-start-up-type': 'cold boot',
      'polyglot-transcribe-from-mic': true,
      'polyglot-transcribe-from-livestream': true,
      'polyglot-view-past-transcriptions': true,
      'polyglot-share-via-public-url': '5 minute limit',
      'polyglot-translate-via-public-url': '5 minute limit',
      'polyglot-password-protected': false,
      'polyglot-scheduled-livestream-transcription': false,
      'ai-language-support': '57 languages plus dialects & accents',
      'ai-language-detection': true,
      'ai-segmentation': true,
      'ai-summarization': true,
      'ai-timestamps': true,
      'ai-alignment': true,
      'ai-diarization': false,
      'support-contact': 'Email and Live Chat Support',
      'support-sla': false,
    },
  },
  premium: {
    label: 'Premium',
    description: 'A plan that scales with your requirements',
    monthly_price: pricingData.premium.monthly_price,
    yearly_price: pricingData.premium.yearly_price,
    featured: true,
    highlights: [
      `${pricingData.premium.free_hours} hours of free transcriptions each month`,
      `${moneyValue(pricingData.premium.prerecorded_rate)} per additional prerecorded transcription hour`,
      `${moneyValue(pricingData.premium.live_rate)} per additional live transcription hour`,
      'Unrestricted access to Polyglot',
      'API for programmatic access',
    ],
    features: {
      'plan-price': false,
      'transcription-free-hours': { free: pricingData.premium.free_hours },
      'transcription-prerecorded': { rate: pricingData.premium.prerecorded_rate },
      'transcription-live': { rate: pricingData.premium.live_rate },
      'dashboard-polyglot': true,
      'dashboard-upload-from-file': true,
      'dashboard-import-from-url': true,
      'dashboard-record-from-mic': true,
      'dashboard-translations': true,
      'dashboard-export': true,
      'developers-api': true,
      'developers-database': false,
      'developers-managed-services': false,
      'developers-rate-limit': 'max 50 concurrent sessions',
      'developers-start-up-type': 'warm boot in non-peak times',
      'polyglot-transcribe-from-mic': true,
      'polyglot-transcribe-from-livestream': true,
      'polyglot-view-past-transcriptions': true,
      'polyglot-share-via-public-url': true,
      'polyglot-translate-via-public-url': true,
      'polyglot-password-protected': true,
      'polyglot-scheduled-livestream-transcription': false,
      'ai-language-support': '57 languages plus dialects & accents',
      'ai-language-detection': true,
      'ai-segmentation': true,
      'ai-summarization': true,
      'ai-timestamps': true,
      'ai-alignment': true,
      'ai-diarization': false,
      // 'features-video-generation': true,
      // 'features-live-transcription-app': true,
      // 'features-web-ui-widgets': true,
      'support-contact': 'Email and Live Chat Support',
      'support-sla': false,
    },
  },
  enterprise: {
    label: 'Enterprise',
    description: 'Dedicated support and infrastructure',
    highlights: ['Unlimited transcriptions', 'Unlimited concurrent sessions', 'Dedicated IT support', 'Custom SLA'],
    features: {
      'plan-price': 'custom pricing',
      'transcription-free-hours': '',
      'transcription-prerecorded': { custom: true },
      'transcription-live': { custom: true },
      'dashboard-polyglot': true,
      'dashboard-upload-from-file': true,
      'dashboard-import-from-url': true,
      'dashboard-record-from-mic': true,
      'dashboard-export': true,
      'dashboard-translations': true,
      'developers-start-up-type': 'warm boot',
      // 'features-video-generation': true,
      // 'features-live-transcription-app': true,
      // 'features-web-ui-widgets': true,
      'developers-api': true,
      'developers-database': true,
      'developers-managed-services': true,
      'developers-rate-limit': 'unlimited concurrent sessions',
      'polyglot-transcribe-from-mic': true,
      'polyglot-transcribe-from-livestream': true,
      'polyglot-view-past-transcriptions': true,
      'polyglot-share-via-public-url': true,
      'polyglot-translate-via-public-url': true,
      'polyglot-password-protected': true,
      'polyglot-scheduled-livestream-transcription': true,
      'ai-language-support': '57 languages plus dialects & accents',
      'ai-language-detection': true,
      'ai-segmentation': true,
      'ai-summarization': true,
      'ai-timestamps': true,
      'ai-alignment': true,
      'ai-diarization': true,
      'support-contact': 'Dedicated Account Manager',
      'support-sla': true,
    },
  },
};
