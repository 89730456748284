'use client';

import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { MaxWidthContainer } from './Layouts/MaxWidthContainer';
import { Heading } from './Fields/Heading';
import { ButtonAnchor, GhostButton, GhostFakeButton } from './Fields/Buttons';
import { CountryFlag } from './widgets/CountryFlag/CountryFlag';
import { LanguageSelector } from './Navigation/SecondaryNavigation/LanguageSelector';

import { getBrowserCookieValue } from '@/utils/cookies';
import { LocaleKey, SUGGESTED_LOCALE_COOKIE, locales, zLocale } from '@/constants/locales';
import { getLocaleSpecificUrl } from '@/utils/locale';

type LocaleRedirectNoticeProps = {
  localeKey: LocaleKey;
};

const translatedPrompt: Record<LocaleKey, string> = {
  af: 'Wil jy hierdie webwerf in Afrikaans sien?',
  ar: 'هل تود عرض هذا الموقع باللغة العربية؟',
  hy: 'Դուք ցանկանում եք տեսնել այս վեբဆိုքոնը հայերենով?',
  az: 'Bu veb saytını Azərbaycan dilində görmək istəyirsiniz?',
  be: 'Ці жадаеце вы праглядзець гэты вэб-сайт на беларускай мове?',
  bs: 'Želite li pogledati ovu web stranicu na bosanskom jeziku?',
  bg: 'Бихте ли искали да видите този уебсайт на български?',
  ca: 'Voleu veure aquest lloc web en català?',
  zh: '您想以中文查看此网站吗？',
  hr: 'Želite li pregledati ovu web stranicu na hrvatskom jeziku?',
  cs: 'Chcete zobrazit tuto webovou stránku v češtině?',
  da: 'Vil du se dette websted på dansk?',
  nl: 'Wilt u deze website in het Nederlands bekijken?',
  en: 'Would you like to view this website in English?',
  et: 'Kas soovite seda veebisaiti eesti keeles vaadata?',
  fi: 'Haluatko katsella tätä verkkosivustoa suomeksi?',
  fr: 'Voulez-vous voir ce site Web en français?',
  gl: '¿Desexa ver este sitio web en galego?',
  de: 'Möchten Sie diese Website auf Deutsch ansehen?',
  el: 'Θέλετε να δείτε αυτόν τον ιστότοπο στα Ελληνικά;',
  he: 'האם תרצה לצפות באתר זה בעברית?',
  hi: 'क्या आप इस वेबसाइट को हिंदी में देखना चाहेंगे?',
  hu: 'Szeretné megtekinteni ezt a weboldalt magyar nyelven?',
  is: 'Viltu skoða þessa vefsíðu á íslensku?',
  id: 'Apakah Anda ingin melihat situs web ini dalam bahasa Indonesia?',
  it: 'Vuoi visualizzare questo sito web in italiano?',
  ja: 'このウェブサイトを日本語で表示しますか？',
  kn: 'ಈ ವೆಬ್ಸೈಟ್ ಅನ್ನು ಕನ್ನಡದಲ್ಲಿ ವೀಕ್ಷಿಸಬೇಕೇ?',
  kk: 'Бұл веб-сайтты қазақ тілінде қарағыңыз келе ме?',
  ko: '이 웹사이트를 한국어로 보시겠습니까?',
  lv: 'Vai vēlaties skatīt šo mājaslapu latviešu valodā?',
  lt: 'Ar norite peržiūrėti šį tinklalapį lietuviškai?',
  mk: 'Дали сакате да го прегледате овој вебсајт на македонски?',
  ms: 'Adakah anda ingin melihat laman web ini dalam Bahasa Melayu?',
  mr: 'आपण या वेबसाइटला मराठीत पाहू इच्छिता का?',
  ne: 'के तपाईं यो वेबसाइट नेपाली भाषामा हेर्न चाहनुहुन्छ?',
  no: 'Vil du se dette nettstedet på norsk?',
  fa: 'آیا می‌خواهید این وب‌سایت را به فارسی مشاهده کنید؟',
  pl: 'Czy chcesz wyświetlić tę stronę internetową w języku polskim?',
  pt: 'Gostaria de ver este site em português?',
  ro: 'Doriți să vizualizați acest site în limba română?',
  ru: 'Вы хотите просмотреть этот сайт на русском языке?',
  sr: 'Да ли желите да прегледате ову веб страницу на српском језику?',
  sk: 'Chcete si zobraziť túto webovú stránku v slovenčine?',
  sl: 'Želite si ogledati to spletno stran v slovenščini?',
  es: '¿Desea ver este sitio web en español?',
  sw: 'Je, ungependa kuona tovuti hii kwa Kiswahili?',
  sv: 'Vill du visa denna webbplats på svenska?',
  tl: 'Gusto mo bang tingnan ang website na ito sa Tagalog?',
  ta: 'இந்த வலைத்தளத்தை தமிழில் பார்க்க விரும்புகிறீர்களா?',
  th: 'คุณต้องการดูเว็บไซต์นี้เป็นภาษาไทยหรือไม่?',
  tr: 'Bu web sitesini Türkçe görmek ister misiniz?',
  uk: 'Ви хочете переглянути цей вебсайт українською мовою?',
  ur: 'کیا آپ اس ویب سائٹ کو اردو میں دیکھنا چاہیں گے؟',
  vi: 'Bạn có muốn xem trang web này bằng tiếng Việt không?',
  cy: 'A hoffet ti weld y wefan hon yn Gymraeg?',
};

export const LocaleRedirectNotice = ({ localeKey }: LocaleRedirectNoticeProps) => {
  const [suggestedLocaleKey, setSuggestedLocaleKey] = useState<LocaleKey>();
  const [suggestedLocalePath, setSuggestedLocalePath] = useState<string>();

  const [closed, setClosed] = useState<boolean>(true);

  useEffect(() => {
    const browserPreferredLocale = navigator.language.split('-')[0];
    const newLocale = zLocale.safeParse(getBrowserCookieValue(SUGGESTED_LOCALE_COOKIE) ?? browserPreferredLocale)?.data;
    if (!newLocale) return;
    setSuggestedLocaleKey(newLocale);
    setSuggestedLocalePath(getLocaleSpecificUrl(newLocale, window.location.pathname));
    if (suggestedLocaleKey !== localeKey) {
      setTimeout(() => setClosed(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!suggestedLocaleKey || !suggestedLocalePath || suggestedLocaleKey === localeKey) {
    return null;
  }

  const currentLocale = locales.find((l) => l.id === localeKey);
  const suggestedLocale = locales.find((l) => l.id === suggestedLocaleKey);

  const selectLocale = (localeKey: LocaleKey) => {
    document.cookie = `${SUGGESTED_LOCALE_COOKIE}=${localeKey}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
    setClosed(true);
  };

  return (
    <div
      className={classNames('fixed bottom-4 z-50 w-full opacity-100 transition-all duration-500 sm:bottom-10', {
        '!scale-0': closed,
      })}
      suppressHydrationWarning
    >
      <MaxWidthContainer className="relative w-[calc(100%-2rem)] !p-0 sm:w-[calc(100%-5rem)]">
        <div className="flex flex-col items-center justify-center gap-10 rounded-lg bg-white/80 p-4 backdrop-blur-lg dark:bg-background-950/80 sm:p-10">
          <div className="flex flex-col items-center justify-center gap-4">
            <Heading level={3}>{translatedPrompt[suggestedLocaleKey]}</Heading>
            {suggestedLocaleKey !== 'en' && <div className="font-extralight">Please select your language</div>}
          </div>

          <GhostButton
            className="absolute right-2 top-2 !min-w-0 sm:right-5 sm:top-5"
            onClick={() => selectLocale(localeKey)}
          >
            <XMarkIcon className="h-4 w-4 sm:h-5 sm:w-5" />
          </GhostButton>
          <div className="flex flex-wrap justify-center gap-4">
            <ButtonAnchor onClick={() => selectLocale(suggestedLocaleKey)} href={suggestedLocalePath}>
              <CountryFlag code={locales.find((l) => l.id === suggestedLocaleKey)?.country || ''} rounded />
              {suggestedLocale?.actualTitle}
            </ButtonAnchor>
            <GhostButton onClick={() => selectLocale(localeKey)}>
              <CountryFlag code={locales.find((l) => l.id === localeKey)?.country || ''} rounded />
              {currentLocale?.actualTitle}
            </GhostButton>
            <GhostFakeButton className="p-0">
              <LanguageSelector
                blocking
                className="mr-auto [&_button]:flex-nowrap"
                onLanguageChange={(newLocale) => {
                  selectLocale(newLocale);
                  window.location.href = getLocaleSpecificUrl(newLocale, window.location.pathname);
                }}
              >
                <span className="flex items-center gap-1.5">
                  <CountryFlag code="fr" />
                  <CountryFlag code="es" />
                  <CountryFlag code="cn" />
                  <CountryFlag code="in" />
                  <CountryFlag code="kr" />
                  <CountryFlag code="ro" />
                  <span className="font-bold opacity-50 dark:text-white dark:opacity-80">+50</span>
                </span>
              </LanguageSelector>
            </GhostFakeButton>
          </div>
        </div>
        <CountryFlag
          code={locales.find((l) => l.id === suggestedLocaleKey)?.country || ''}
          className="!absolute -left-2 -top-2 -z-10 !h-[calc(100%+1rem)] !w-[calc(100%+1rem)] rounded-lg !bg-cover opacity-40 blur filter dark:opacity-60 dark:brightness-[50%]"
        />
      </MaxWidthContainer>
    </div>
  );
};
