/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Config } from 'tailwindcss';

import { colors } from './constants/tailwindColors';

const config: Config = {
  darkMode: 'class',
  content: [
    './pages/**/*.{js,ts,jsx,tsx,mdx}',
    './components/**/*.{js,ts,jsx,tsx,mdx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      colors,
      backgroundImage: {
        'pattern-1': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%239C92AC' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E")`,
      },
      listStyleType: {
        square: 'square',
        roman: 'lower-roman',
      },
      screens: {
        'lt-short': { raw: '(max-height: 549px)' },
        short: { raw: '(min-height: 550px)' },
        'lt-tall': { raw: '(max-height: 767px)' },
        tall: { raw: '(min-height: 768px)' },
        'lt-tiny': { raw: '(max-width: 450px)' },
        'lt-sm': { raw: '(max-width: 639px)' },
        'lt-md': { raw: '(max-width: 767px)' },
        'lt-lg': { raw: '(max-width: 1023px)' },
        'not-touch': { raw: '(hover: hover) and (pointer: fine)' },
        'has-touch': { raw: '(hover: none), (pointer: coarse)' },
        'touch-landscape': { raw: '(hover: none) and (pointer: coarse) and (orientation: landscape)' },
      },
      animation: {
        'shake-x': 'shake-x 0.82s cubic-bezier(.36,.07,.19,.97) both',
        'shake-y': 'shake-y 0.82s cubic-bezier(.36,.07,.19,.97) both',
        'slide-up': 'slide-up 0.82s cubic-bezier(.36,.07,.19,.97) both',
        'slide-down': 'slide-down 0.82s cubic-bezier(.36,.07,.19,.97) both',
      },
      keyframes: {
        'shake-x': {
          '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
          },
          '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
          },
          '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
          },
          '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
          },
        },
        'shake-y': {
          '10%, 90%': {
            transform: 'translate3d(0, -1px, 0)',
          },
          '20%, 80%': {
            transform: 'translate3d(0, 2px, 0)',
          },
          '30%, 50%, 70%': {
            transform: 'translate3d(0, -3px, 0)',
          },
          '40%, 60%': {
            transform: 'translate3d(0, 3px, 0)',
          },
        },
        'slide-up': {
          '0%': {
            'max-height': '0',
            'margin-bottom': '-1rem',
          },
          '100%': {
            'max-height': '250px',
            'margin-bottom': '0',
          },
        },
        'slide-down': {
          '0%': {
            transform: 'translateY(-100%)',
            opacity: '0',
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: '1',
          },
        },
      },
    },
  },
  plugins: [
    function ({ addVariant, e }: { addVariant: any; e: any }) {
      addVariant('pdf', ({ modifySelectors, separator }: { modifySelectors: any; separator: any }) => {
        modifySelectors(({ className }: { className: any }) => {
          return `body.pdf .${e(`pdf${separator}${className}`)}`;
        });
      });
    },
    require('tailwindcss-3d'),
  ],
};
export default config;
