/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames';

type LogoProps = {
  className?: string;
  iconOnly?: boolean;
  defer?: boolean;
  showBeta?: boolean;
  betaWrapperCSS?: string;
};

export const betaCSS =
  'relative mr-11 font-normal after:absolute after:-right-1 after:bottom-0 after:translate-x-full after:rounded after:bg-green-600 after:px-1 after:text-sm after:text-white after:content-["Beta"] dark:after:bg-green-700';

export const Logo = ({ className, iconOnly, showBeta, betaWrapperCSS, defer }: LogoProps) => (
  <div className={classNames(!showBeta ? undefined : betaCSS, betaWrapperCSS)}>
    <img
      src={iconOnly ? '/favicon-dark.svg' : '/logo-dark-mode.svg'}
      alt="VocalStack Logo"
      width="25"
      height="19"
      className={classNames('hidden pdf:!hidden dark:block print:!hidden', className)}
      suppressHydrationWarning
      {...(defer ? { loading: 'lazy' } : {})}
    />
    <img
      src={iconOnly ? '/favicon-light.svg' : '/logo.svg'}
      alt="VocalStack Logo"
      width="25"
      height="19"
      className={classNames('block pdf:!block dark:hidden print:!block', className)}
      suppressHydrationWarning
      {...(defer ? { loading: 'lazy' } : {})}
    />
  </div>
);
