'use client';

import { createContext, useContext, useState, useEffect } from 'react';

import { TRANSLATIONS_COOKIE, Language, LocaleKey } from '@/constants/locales';

const defaultLanguages: Language[] = ['Detected'];

type TranslationsContextType = {
  translations: Language[];
  translationsWithoutDetected: LocaleKey[];
  setTranslations: React.Dispatch<React.SetStateAction<Language[]>>;
};

const TranslationsContext = createContext<TranslationsContextType>({
  translations: defaultLanguages,
  translationsWithoutDetected: [],
  setTranslations: () => {},
});

export const TranslationsProvider = (props: { children: React.ReactNode; languages?: Language[] }) => {
  const { children, languages: l } = props;
  const [translations, setTranslations] = useState<Language[]>(l ?? defaultLanguages);

  useEffect(() => {
    document.cookie = `${TRANSLATIONS_COOKIE}=${JSON.stringify(translations.slice(0, 1))}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
  }, [translations]);

  const translationsWithoutDetected = translations.filter((lang) => lang !== 'Detected') as LocaleKey[];

  const value = { translations, translationsWithoutDetected, setTranslations };

  return <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>;
};

export function useTranslations() {
  return useContext(TranslationsContext);
}
