import { useEffect, useState } from 'react';

import classNames from 'classnames';

export type AutoSnapProps = {
  children: React.ReactNode[];
  className?: string;
  disabled?: boolean;
};

export const AutoSnap = ({ className, children, disabled }: AutoSnapProps) => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (disabled) {
    return children;
  }

  return (
    <div
      className={classNames(
        'relative z-40 w-full',
        'overflow-y-auto overflow-x-clip',
        'snap-y',
        {
          'snap-mandatory': !mounted,
          'snap-proximity': mounted,
        },
        className,
      )}
      style={{
        overscrollBehaviorY: 'contain',
      }}
    >
      {children}
      <span className="relative -z-10 w-full snap-end" />
    </div>
  );
};
